import { useParams } from "react-router-dom"
import { Footer } from "../components/Global/Footer/Footer"
import Navbar from "../components/Global/Navbar/Navbar"
import LonghornsStockSection from "../components/LonghornsStock/LonghornsStockSection"
import LonghornsStockWrapper from "../components/LonghornsStock/LonghornsStockWrapper"

export const LonghornsStock: React.FC = () => {
    
    const params = useParams();
    var filter = "";
    if (params.id !== undefined) {
        filter = params.id;
    }

    return (
        <>
            <Navbar />
            <LonghornsStockWrapper filter={filter}/>
            <LonghornsStockSection filter={filter}/>
            <Footer />
        </>
    )
}