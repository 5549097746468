import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Box
} from '@mui/material';
import { colors } from '../../../styles/colors';
import { isStorageConfigured, uploadFileToBlob } from '../../Admin/AdminImageUpload';
import { theme } from '../../../styles/theme';
import { alpha } from '@mui/material';

const useStyles = makeStyles({
    root: {
        [theme.breakpoints.up('md')]: {
            width: '20vw'
        },
        [theme.breakpoints.between('sm', 'md')]: {
            width: '40vw'
        },
        [theme.breakpoints.down('sm')]: {
            width: '60vw'
        },
        margin: 'auto',
        padding: 20,
        backgroundColor: colors.palette.primary.light,
        "&.MuiPaper-root": {
            backgroundColor: alpha(colors.palette.primary.light, .85),
            borderRadius: '8px',
            borderColor: colors.palette.primary.main,
            borderWidth: '2px',
            borderStyle: 'solid',
        }
    },
    dropzone: {
        height: 200,
        border: '1px dashed black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer'
    },
});

type props = {
    id: number,
    type: string
}

export default function DragAndDropFile(props: props) {
    const classes = useStyles();

    const storageConfigured = isStorageConfigured();

    // all blobs in container
    const [message, setMessage] = useState<string>('');

    // current file to upload into container
    const [fileSelected, setFileSelected] = useState<File | null>();

    // UI/form management
    const [uploading, setUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));

    const onFileChange = (event: any) => {
        // capture file into state
        setFileSelected(event.target.files[0]);
    };

    // Function to handle file drop
    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        // Get the files from the event
        const droppedFile = event.dataTransfer.files[0];

        // Filter out any files that are not JPEGs
        if (droppedFile.type !== 'image/jpeg') {
            setMessage('Please upload a JPEG/JPG file')
        }

        // Set the files in state
        setFileSelected(droppedFile);
    };

    const onFileUpload = async () => {

        setMessage('')

        if (!fileSelected) {
            setMessage('Please select a jpeg/jpg image file');
            (document.getElementById('message-' + props.id) as HTMLInputElement).style.color = 'red'
            return
        }

        const caption = (document.getElementById('caption-' + props.id) as HTMLInputElement).value

        if (caption === '') {
            setMessage('Please enter an image caption');
            (document.getElementById('message-' + props.id) as HTMLInputElement).style.color = 'red'
            return
        }

        // prepare UI
        setUploading(true);

        // *** UPLOAD TO AZURE STORAGE ***
        await uploadFileToBlob(fileSelected, props.id, props.type, caption);

        // prepare UI for results
        setMessage('Image uploaded successfully, reload page to view image.');
        (document.getElementById('message-' + props.id) as HTMLInputElement).style.color = 'green'

        // reset state/form
        setFileSelected(null);
        setUploading(false);
        (document.getElementById('caption-' + props.id) as HTMLInputElement).value = '';
        setInputKey(Math.random().toString(36));
    };


    return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Typography
                        variant="body1"
                        fontSize={'1.2rem'}
                        paddingBottom={'.6rem'}
                    >
                        Drag and Drop File
                    </Typography>
                    {/* Show the dropzone if there are no files selected */}
                    {!fileSelected && (
                        <div
                            className={classes.dropzone}
                            onDrop={handleDrop}
                            onDragOver={(event) => event.preventDefault()}
                        >
                            <Typography variant='button' sx={{ opacity: '60%' }}>Drop JPEG/JPG files here</Typography>
                        </div>
                    )}
                    {/* Show the selected files if there are any */}
                    {fileSelected && (
                        <div>
                            <ul>
                                <li key={fileSelected.name}>{fileSelected.name}</li>
                            </ul>
                        </div>
                    )}

                    <Box sx={{marginTop:"1em"}}>
                        <input type="file" accept="image/jpeg" onChange={onFileChange} key={inputKey || ''}></input>
                    </Box>
                    <Box sx={{marginTop:"1em"}}>
                        <TextField id={'caption-' + props.id} type='text' placeholder='Caption' inputProps={{ maxLength: 50 }}  sx={{display:"flex"}}></TextField>
                    </Box>
                </CardContent>
            </Card>

            {storageConfigured && uploading &&
                <Typography
                    variant='caption'
                    color={colors.palette.text.secondary}
                    sx={{
                        opacity: '60%'
                    }}>
                    Uploading
                </Typography>}

            {!storageConfigured &&
                <Typography
                    variant='caption'
                    color={colors.palette.text.secondary}
                    sx={{
                        opacity: '60%'
                    }}>
                    Storage is not configured.
                </Typography>}

            <Box paddingTop={2} display='flex' justifyContent='end' paddingRight={2}>
                <Button
                    variant="contained"
                    component="label"
                    onClick={onFileUpload}
                >
                    Upload jpeg
                </Button>
            </Box>

            <h3 id={'message-' + props.id}>{message}</h3>
        </>
    );
}