import React from "react";
import { Box, Typography, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";

export const AdminDenizenSection: React.FC = () => {

    return (
        <Box sx={{
            backgroundColor: colors.palette.background.default
        }}>
            <Box
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '4vh',
                        paddingBottom: '8vh'
                    },
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '4vh',
                        paddingBottom: '8vh'
                    }
                }}>
                <Box sx={{ paddingBottom: '8vh' }}>
                    <Button startIcon={<ChevronLeft />} variant='text' href='/Admin' sx={{ color: colors.palette.primary.light }}>
                        Go Back
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography 
                    color={colors.palette.text.secondary} 
                    sx={{opacity: '100%'}} 
                    variant='h3' 
                    fontWeight={600}
                    >
                        Upload Ranch Denizen Photos
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}