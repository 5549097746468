import Navbar from "../../components/Global/Navbar/Navbar"
import AdminAffiliationForm from "../../components/Admin/AdminAffiliationForm"
import React from 'react';
import Login from "../../components/Admin/Login";
import { useParams } from "react-router-dom";
import useToken from "../../components/Admin/SendLogin";

export const AdminAffiliationEdit: React.FC = () => {

    const params = useParams();
    var AffiliationID = 0;
    if (params.id !== undefined) {
        AffiliationID = parseInt(params.id);
    }
    
    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} token={""} />
    }


    return (
        <>
            <Navbar />
            <AdminAffiliationForm AffiliationID={AffiliationID}></AdminAffiliationForm>
        </>
    )
}