import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

type props = {
  RoleID: number,
  Name: string,
  Description: string
};

export default function AdminLonghornRoleForm(props: props) {

  const [role, setRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })

  useEffect(() => {
    async function getRole() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/${props.RoleID}`, { method: 'get' })
      let data = await response.json();
      setRole(await data[0]);
    };

    if (props.RoleID) {
      getRole()
    }

  }, [props.RoleID, role.RoleID]);

  const handleChange = (e: any) => {
    if (e) {
      setRole({ ...role, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const username = sessionStorage.getItem('username')?.slice(1, -1);
    const password = sessionStorage.getItem('password')?.slice(1, -1);

    try {
      console.log("Longhorn role description submitted")
      fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/${props.RoleID}/edit`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body:
          JSON.stringify({
            ...role,
            Username: username,
            Password: password
          })
      })

      let message = document.getElementById("message")
      if (message) {
        message.innerHTML = "Operation completed successfully";
        setTimeout(function () {
          window.location.href = "/Admin/Longhorns/Roles"
        }, 1)
      }
    }
    catch {
      console.log("Creation failed")
    }
  }

  return (
    <Box className="App"
      sx={{
        backgroundColor: colors.palette.background.default,
        color: colors.palette.text.secondary
      }}>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '4vh'
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '3vh'
          }
        }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '6vh',
              paddingBottom: ''
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: '4vh',
              paddingBottom: ''
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                }
              }}
              variant='h4'
              fontWeight={600}
            >
              Enter {props.Name} Description
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('sm')]: {
              paddingTop: '2vh',
              paddingBottom: '8vh'
            },
            [theme.breakpoints.down('sm')]: {
              paddingTop: '2vh',
              paddingBottom: '6vh'
            }
          }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Box
            display={'flex'}
            justifyContent={'center'}
            width='100%'
            paddingTop='1rem'
            sx={{
              [theme.breakpoints.down('md')]: {
                justifyContent: 'start'
              }
            }}
          >
          </Box>
        </Box>
      </Box>
      <Box
        display={'flex'}
        justifyContent={'center'}
        paddingLeft='12vw'
        paddingRight='12vw'
        mb={20}
        sx={{
          [theme.breakpoints.down('md')]: {
            justifyContent: 'start'
          }
        }}
      >
        <form className={'App'} onSubmit={handleSubmit} style={{ width: '20rem' }}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Description"
              name="Description"
              multiline
              rows={4}
              variant="outlined"
              sx={{
                maxLength: 1000,
                '& .MuiFormLabel-root': {
                  color: colors.palette.primary.main + '!important'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: colors.palette.primary.light
                  },
                  '&:hover fieldset': {
                    borderColor: colors.palette.secondary.light
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: colors.palette.primary.main
                  },
                },
              }}
              InputProps={{
                style: { backgroundColor: 'none' }
              }}
              inputProps={{ style: { color: colors.palette.text.secondary } }}
              value={role.Description}
              key="Description"
              onChange={handleChange}
            />
            <Box display={'flex'} justifyContent={'end'} pt={2}>
              <Button sx={{ color: colors.palette.primary.light }} type="submit">{props.RoleID === 0 ? 'Create' : 'Save Changes'}</Button>
              <Box>
                <label id="message"></label>
              </Box>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  )
}