import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { FormGroup, InputLabel, Input } from '@mui/material';
import { colors } from '../../styles/colors';

type props = {
    token: string,
    setToken: any;
}

async function loginUser(credentials: any) {
    return fetch('https://hcrapi.azurewebsites.net/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export default function Login({ token, setToken }: props) {

    const resultBox = document.getElementById('result')
    if (resultBox) {
        resultBox.innerHTML = ''
    }

    const [username, setUserName] = useState<string>();
    const [password, setPassword] = useState<string>();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const result = await loginUser({
            username,
            password
        });

        if (result.message) {
            if (resultBox) {
                resultBox.innerHTML = 'Invalid Login, please try again'
            }
        }
        else if (result.token) {
            setToken(result);
            sessionStorage.setItem('username', JSON.stringify(username));
            sessionStorage.setItem('password', JSON.stringify(password));
        }
    }

    return (
        //Outside box
        <Box
            sx={{
                backgroundColor: colors.palette.background.default
            }}
        >
            {/* Center inside the box */}
            <Box
                height={'100vh'}
                display='flex'
                alignItems='center'
                justifyContent='center'
            >
                <FormGroup
                    sx={{
                        pt: 5
                    }}
                >
                    <Box display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                        <Typography
                            variant='h4'
                            color={colors.palette.text.secondary}
                            sx={{
                                opacity: '100%'
                            }}
                        >
                            Please login
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Box pt={5}>
                                <Box>
                                    <InputLabel sx={{ color: colors.palette.text.secondary, opacity: '60%' }}>Username</InputLabel>
                                    <Input type='text' sx={{ width: '100%' }} onChange={e => setUserName(e.target.value)} />
                                </Box>
                                <Box pt={3}>
                                    <InputLabel sx={{ color: colors.palette.text.secondary, opacity: '60%' }}>Password</InputLabel>
                                    <Input type='password' sx={{ width: '100%' }} onChange={e => setPassword(e.target.value)} />
                                </Box>
                                <InputLabel id="result" sx={{ color: colors.palette.text.secondary }}></InputLabel>
                            </Box>
                            <Box pt={1}>
                                <Button variant='contained' sx={{ height: '3rem', width: '100%' }} type="submit">Login</Button>
                            </Box>
                        </form>
                    </Box>
                </FormGroup>
            </Box>


        </Box>
    )
}