import Navbar from "../components/Global/Navbar/Navbar"
import { IndividualLonghornSection } from "../components/IndividualLonghorn/IndividualLonghornSection"

export const IndividualLonghorn: React.FC = () => {
    return (
        <>
            <Navbar />
            <IndividualLonghornSection />
        </>
    )
}