import Navbar from "../components/Global/Navbar/Navbar"
import { AboutWrapper } from "../components/About/AboutWrapper"
import { AboutSection } from "../components/About/AboutSection"
import { OSUConnection } from "../components/About/OSUConnection"
import { Footer } from "../components/Global/Footer/Footer"

export const About: React.FC = () => {
    return (
        <>
            <Navbar />
            <AboutWrapper />
            <AboutSection />
            <OSUConnection />
            <Footer />
        </>
    )
}