import { ExoticsWrapper } from "../components/Exotics/ExoticsWrapper"
import { ExoticsSection } from "../components/Exotics/ExoticsSection"
import Navbar from "../components/Global/Navbar/Navbar"
import { Footer } from "../components/Global/Footer/Footer"

export const Exotics: React.FC = () => {
    return (
        <>
            <Navbar />
            <ExoticsWrapper />
            <ExoticsSection />
            <Footer />
        </>
    )
}