import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.primary.main,
    [theme.breakpoints.between('lg', 'xl')]: {
        display: 'none'
    },
    [theme.breakpoints.between('xs', 'lg')]: {
        display: 'block'
    }
}));