import styled from '@emotion/styled';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.between('lg', 'xl')]: {
        display: 'block'
    },
    [theme.breakpoints.between('xs', 'lg')]: {
        display: 'none'
    }
}));