import { createGlobalStyle } from "styled-components";
import { Helmet } from "react-helmet";

<Helmet>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
</Helmet>

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    body {
        margin: 0;
        font-family: 'Roboto', sans-serif;
        overscroll-behavior: none;
        color-scheme:dark;
        background-color:#2c2c2c;
    }

    ::selection {
        background: #d4843c;
    }

`

export default GlobalStyles;