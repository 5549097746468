import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

type props = {
  filter: string
}

export default function LonghornsStockWrapper(props: props) {

  return (
    <Box sx={{ backgroundColor: colors.palette.background.default }} pl={"12vw"} pr={"12vw"}>
      <Box pt={4}>
        <Typography
          variant='h2'
          sx={{
            color: colors.palette.text.secondary,
            [theme.breakpoints.up('md')]: {
              fontSize: '3.6rem'
            },
            // [theme.breakpoints.between('md', 'lg')]: {
            //   fontSize: '4rem'
            // },
            [theme.breakpoints.between('sm', 'md')]: {
              fontSize: '2.8rem'
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: '2.2rem'
            },
          }}
        >
          {props.filter ? " " + props.filter.charAt(0).toUpperCase() + props.filter.slice(1) : "All Longhorns"}
        </Typography>
        <Divider sx={{
          width: '10vw',
          backgroundColor: colors.palette.primary.light,
          alignItems: 'start',
          marginTop: '2rem',
          marginBottom: '.5rem'
        }}
        />
        <Typography
          variant='h5'
          fontWeight={300}
          paddingTop={3}
          sx={{
            color: colors.palette.text.secondary,
            opacity: '70%',
            lineHeight: '2.2rem',
            [theme.breakpoints.up('lg')]: {
              width: '28vw'
            },
            [theme.breakpoints.between('md', 'lg')]: {
              width: '32vw',
            },
            [theme.breakpoints.up('sm')]: {
              width: '46vw'
            },
            [theme.breakpoints.down('sm')]: {
              width: '54vw',
              fontSize: '1.04rem',
              lineHeight: '1.6rem'
            },
          }}
        >
          At High Cs Ranch, longhorns are raised, cared for, and bred to produce the healthiest specie possible.
        </Typography>
      </Box>
      {/* </Grid> */}
    </Box >
  )
}