import AdminImageUploadUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import { useParams } from "react-router-dom";
import useToken from "../../components/Admin/SendLogin";
import AdminExoticForm from "../../components/Admin/AdminExoticBreedForm";

export const AdminExoticEdit: React.FC = () => {

    const params = useParams();
    var breedID = 0;
    if (params.id !== undefined) {
        breedID = parseInt(params.id);
    }
    
    const [breed, setBreed] = useState<{BreedID: number, Name: string, Description:string}>({BreedID: 0, Name: "", Description:""})

    useEffect(() => {
        async function getBreed() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/${breedID}`, { method: 'get' })
            let data = await response.json();
            setBreed(data[0]);
        };

        if (breedID) {
            getBreed();
        }

    }, [breed, breedID]);

    const [imageList, setImageList] = useState<{URL: string, Caption: string}[]>([]);
  
    useEffect(() => {
      async function getImages() {
        let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/${breedID}/images`, {method: 'get'})
        let data = await response.json();
        setImageList(data);
      };
      
      if (imageList.length === 0) {
        getImages();
      }
    }, [breedID, imageList.length]);

    const { token, setToken } = useToken();
    
    if (!token) {
        return <Login setToken={setToken} token={""} />
    }

    return (
        <>
            <Navbar />
            <AdminExoticForm {...breed}></AdminExoticForm>
            {breedID > 0 && <AdminImageUploadUI title="Upload photo for this breed's slideshow" type="exotic" id={breedID} imageList={imageList}></AdminImageUploadUI>}
        </>
    )
}