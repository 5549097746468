import React from 'react';
// import Path from 'path';
import { deleteBlobIfItExists, isStorageConfigured } from './AdminImageUpload';
import { Box, Typography, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';
import DeleteIcon from '@mui/icons-material/Delete';
import DragAndDropFile from '../Global/DragAndDrop/DragAndDrop';

const storageConfigured = isStorageConfigured();

type props = {
  type: string,
  id: number,
  title: string,
  imageList: Array<{ URL: string, Caption: string }>
}

export default function AdminImageUI(props: props) {

  const deleteImage = (event: any) => {
    var URL = event.target.getAttribute('data-src');
    var ImageName = event.target.getAttribute('data-id');
    var Button = event.target
    var Image = document.getElementById(ImageName)

    if (Image != null) {
      Image.style.display = 'none';
    }

    if (Button) {
      Button.style.display = 'none';
    }

    deleteBlobIfItExists(URL);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          [theme.breakpoints.up('sm')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '2vh',
            paddingBottom: '13vh'
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '2vh',
            paddingBottom: '6vh'
          }
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Typography
          variant='body1'
          width={'100%'}
          textAlign='center'
          color={colors.palette.text.secondary}
          sx={{
            opacity: '80%',
            [theme.breakpoints.down('md')]: {
              textAlign: 'start',
              paddingBottom: 1,
              fontSize: '1rem'
            }
          }}
        >
          {props.title}
        </Typography>

        <Typography
          variant='body2'
          width={'100%'}
          color={colors.palette.text.secondary}
          sx={{
            opacity: '40%',
            paddingTop: '4px',
            textAlign: 'center',
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
              textAlign: 'start'
            }
          }}
        >
          Accepts jpeg files. A 3/2 aspect ratio works best (ie, 2160×1440). If upload gets stuck, please reload page and try again.
        </Typography>

        {storageConfigured ? <Box pt={6} pb={6}>
          <DragAndDropFile id={props.id} type={props.type} />
        </Box> : <h4>Connection to Azure Blob Storage is not working at the moment</h4>}


        <Box
          display={'flex'}
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          {props.imageList.length > 0 &&
            <Typography
              variant='body1'
              color={colors.palette.text.secondary}
              paddingTop={2}
              paddingBottom={4}
              sx={{
                opacity: '80%',
                [theme.breakpoints.down('md')]: {
                  textAlign: 'start'
                }
              }}
            >
              Manage Images
            </Typography>
          }
          <Box>
            <Box>
              {props.imageList?.map(({ URL, Caption }, index) => (
                <React.Fragment key={index}>
                  <Box
                    component={'img'}
                    src={URL}
                    alt={Caption}
                    id={`Image-${index}`}
                    key={index}
                    height='20rem'
                    sx={{
                      objectFit: 'cover',
                      // image size on screens below small
                      [theme.breakpoints.down('sm')]: {
                        height: '240px',
                        borderRadius: '36px'
                      },
                      borderRadius: '64px',
                      display: 'block',
                      overflow: 'hidden',
                      width: '100%',
                      aspectRatio: '3/2'
                    }}
                  >
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'end'}
                    paddingTop={'1.4vh'}
                    paddingBottom={'3vh'}
                    paddingRight={'2vw'}
                  >
                    <Button
                      variant='contained_uploadUI'
                      id={`Delete-${index}`}
                      data-src={URL}
                      data-id={`Image-${index}`}
                      onClick={deleteImage}
                      endIcon={<DeleteIcon />}
                    >
                      Delete
                    </Button>
                  </Box>
                </React.Fragment>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};