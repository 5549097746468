import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react'
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

type props = {
  MeasurementID: number,
  longhornID: number
};

export default function AdminTipMeasureForm(props: props) {

  const [measure, setMeasure] = useState<{ MeasurementID: number, LonghornID: number, Length: string, Date: string }>({ MeasurementID: 0, LonghornID: 0, Length: '', Date: '' });

  useEffect(() => {
    async function getMeasure() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${props.longhornID}/tipmeasures/${props.MeasurementID}`, { method: 'get' })
      let data = await response.json();
      setMeasure(await data[0]);
    };

    if (measure.MeasurementID) {
      getMeasure()
    }

  }, [props.longhornID, props.MeasurementID, measure.MeasurementID]);


  const handleChange = (e: any) => {
    if (e) {
      setMeasure({ ...measure, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    try {
      const username = sessionStorage.getItem('username')?.slice(1, -1);
      const password = sessionStorage.getItem('password')?.slice(1, -1);

      console.log("Tip-to-tip length form submitted")
      if (props.MeasurementID !== 0) {
        fetch(`https://hcrapi.azurewebsites.net/longhorns/${props.longhornID}/tipmeasures/${props.MeasurementID}/edit`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...measure,
              Username: username,
              Password: password
            })
        })
      }

      else {
        fetch(`https://hcrapi.azurewebsites.net/longhorns/${props.longhornID}/tipmeasures/add`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...measure,
              Username: username,
              Password: password
            })
        })
      }
      let message = document.getElementById("message")
      if (message) {
        message.innerHTML = "Operation completed successfully";
        setTimeout(function () {
          window.location.href = `/Admin/Longhorns/${props.longhornID}/TipMeasures`
        }, 1500)
      }
    }
    catch {
      console.log("Creation failed")
    }
  }

  return (
    <>
      <Box sx={{
        backgroundColor: colors.palette.background.default,
        color: colors.palette.text.secondary
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href={`/Admin/Longhorns/${props.longhornID}/TipMeasures`} sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              Add New Tip to Tip Measurement
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent={'center'} mt={10} mb={20}
            sx={{
              [theme.breakpoints.down('md')]: {
                justifyContent: 'start'
              }
            }}
          >
            <form className={'App'} onSubmit={handleSubmit} style={{ width: '20rem' }}>
              <Stack spacing={3}>
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  required
                  label="Length"
                  type="text"
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                  value={measure.Length}
                  name="Length"
                  onChange={handleChange}
                  key="Length"
                />
                <TextField fullWidth
                  required
                  id="date"
                  label="Date"
                  type="date"
                  value={measure.Date}
                  onChange={handleChange}
                  name="Date"
                  key="Date"
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  inputProps={{ style: { color: colors.palette.text.secondary } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Box display={'flex'} justifyContent={'end'} pt={2}>
                  <Button sx={{ color: colors.palette.primary.light }} type="submit">{props.MeasurementID === 0 ? "Create" : "Edit"}</Button>
                  <Box>
                    <label id="message"></label>
                  </Box>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  )
}