import React from "react";
import { Box, Typography, Button, Link } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from "../../styles/theme";
import hill from '../../assets/hill.png'

export const AboutSection = () => {

    return (
        <Box
            sx={{
                backgroundImage: `url(${hill})`,
                backgroundColor: colors.palette.primary.light,
                backgroundSize: '80%',
                backgroundRepeat: 'no-repeat',
                backgroundBlendMode: '',
                backgroundPosition: 'right bottom'
            }}
        >
            <Box
                sx={{
                    paddingLeft: '12vw',
                    paddingRight: '12vw',
                    [theme.breakpoints.up('sm')]: {
                        paddingTop: '13vw',
                        paddingBottom: '13vw',
                    },
                    [theme.breakpoints.down('sm')]: {
                        paddingTop: '36vw',
                        paddingBottom: '36vw',
                    },
                }}
            >
                <Typography
                    variant="h3"
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            width: '40vw',
                        },
                        [theme.breakpoints.down('md')]: {
                            width: '60vw',
                        },
                    }}
                >
                    A beautiful ranch for longhorns, exotics, and amazing sunsets.
                </Typography>
                <Box pt={8}>

                    <Link href='/About' style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Button variant='contained'>
                            <Typography variant="button">About</Typography>
                    </Button>
                    </Link>
                </Box>
            </Box>
        </Box>
    );
};
