import React from "react";
import { ThemeProvider, responsiveFontSizes } from "@mui/material";
import { theme } from "./styles/theme";
import GlobalStyles from "./global";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from "./pages/Home";
import { About } from "./pages/About";
import { RegisteredLonghorns } from "./pages/Longhorns";
import { Exotics } from "./pages/Exotics";
import { Affiliations } from "./pages/Affiliations";
import { OtherDenizens } from "./pages/OtherDenizens";
import { Admin } from "./pages/Admin/Admin";
import { AdminLonghorn } from "./pages/Admin/AdminLonghorn";
import { AdminLonghornEdit } from "./pages/Admin/AdminLonghornEdit";
import { AdminExotic } from "./pages/Admin/AdminExotic";
import { AdminDenizen } from "./pages/Admin/AdminDenizen";
import { AdminRanch } from "./pages/Admin/AdminRanch";
import { IndividualLonghorn } from "./pages/IndividualLonghorn";
import { IndividualExotic } from "./pages/IndividualExotic";
import { AdminExoticEdit } from "./pages/Admin/AdminExoticEdit";
import { AdminTipMeasure } from "./pages/Admin/AdminTipMeasure";
import { AdminTipMeasureEdit } from "./pages/Admin/AdminTipMeasureEdit";
import { AdminLonghornRole } from "./pages/Admin/AdminLonghornRole";
import { LonghornsStock } from "./pages/LonghornsStock";
import { AdminLonghornRoleEdit } from "./pages/Admin/AdminLonghornRoleEdit"
import { AdminAffiliations } from "./pages/Admin/AdminAffiliations";
import { AdminAffiliationEdit } from "./pages/Admin/AdminAffiliationEdit";

function App() {
    return (
        <>
            <ThemeProvider theme={responsiveFontSizes(theme)}>
                <GlobalStyles />
                <Router>
                    <Routes>
                        <Route index element={<Home />} />
                        <Route path="/Longhorns/List/" element={<LonghornsStock />}/>
                        <Route path="/Longhorns/List/:id" element={<LonghornsStock />}/>
                        <Route path="/Longhorns" element={<RegisteredLonghorns />}/>
                        <Route path="/Exotics" element={<Exotics />}/>
                        <Route path="/Affiliations" element={<Affiliations />}/>
                        <Route path="/Denizens" element={<OtherDenizens />}/>
                        <Route path="/About" element={<About />}/>
                        <Route path="/Admin" element={<Admin />}/>
                        <Route path="/Admin/Longhorns/" element={<AdminLonghorn />}/>
                        <Route path="/Admin/Longhorns/Roles/:id" element={<AdminLonghornRoleEdit />}/>
                        <Route path="/Admin/Longhorns/Roles" element={<AdminLonghornRole />}/>
                        <Route path="/Admin/Longhorns/:id" element={<AdminLonghornEdit/>}/>
                        <Route path="/Admin/Exotics/:id" element={<AdminExoticEdit/>}/>
                        <Route path="/Admin/Exotics" element={<AdminExotic />}/>
                        <Route path="/Admin/Denizens" element={<AdminDenizen />}/>
                        <Route path="/Admin/Ranch" element={<AdminRanch />}/>
                        <Route path="/Longhorns/:id" element={<IndividualLonghorn />}/>
                        <Route path="/Exotics/:id" element={<IndividualExotic />}/>
                        <Route path="/Admin/Longhorns/:id/TipMeasures/:measureid" element={<AdminTipMeasureEdit/>}/>
                        <Route path="/Admin/Longhorns/:id/TipMeasures" element={<AdminTipMeasure/>}/>
                        <Route path="/Admin/Affiliations" element={<AdminAffiliations/>}/>
                        <Route path="/Admin/Affiliations/:id" element={<AdminAffiliationEdit/>}/>
                    </Routes>
                </Router>
            </ThemeProvider>

        </>

    );
}

export default App;