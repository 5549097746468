import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { colors } from '../../styles/colors';
// import { HomeCarousel } from './HomeCarousel';
import { theme } from '../../styles/theme';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useParams } from 'react-router-dom';
import { Carousel } from '../Global/Carousel/Carousel';

export const IndividualExoticSection: React.FC = () => {

    const params = useParams();
    var breedID = 0;
    if (params.id !== undefined) {
        breedID = parseInt(params.id);
    }

    const [breed, setBreed] = useState<{ BreedID: number, Name: string, Description: string }>({ BreedID: 0, Name: "", Description: "" })

    useEffect(() => {
        async function getLonghorn() {
            if (breedID !== 0) {
                let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/${breedID}`, { method: 'get' })
                let data = await response.json();
                setBreed(data[0]);
            }
        };

        if (breed.Name === "") {
            getLonghorn();
        }

    }, [breed.Name, breedID]);

    const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

    useEffect(() => {
        async function getImages() {
            if (breedID !== 0) {
                let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/${breedID}/images`, { method: 'get' })
                let data = await response.json();
                setImageList(await data);
            }
        };

        if (imageList.length === 0) {
            getImages();
        }
    }, [imageList.length, breedID]);

    return (
        <Box sx={{ backgroundColor: colors.palette.background.default, opacity: '100%' }}>
            <Grid
                container spacing={6} justifyContent='space-between'
                pl={"12vw"} pr={"12vw"}
                sx={{
                    display: 'flex',
                    [theme.breakpoints.up('md')]: {
                        paddingTop: '8vh',
                        paddingBottom: '10vh'
                    },
                    [theme.breakpoints.down('md')]: {
                        paddingTop: '0vh',
                        paddingBottom: '15vh'
                    },
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <Grid item md={6}
                >
                    <Box pt={4}>
                        <Typography
                            variant='h2'
                            sx={{
                                color: colors.palette.text.secondary,
                                [theme.breakpoints.up('md')]: {
                                    fontSize: '3.6rem'
                                },
                                // [theme.breakpoints.between('md', 'lg')]: {
                                //   fontSize: '4rem'
                                // },
                                [theme.breakpoints.between('sm', 'md')]: {
                                    fontSize: '2.8rem'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '2.2rem'
                                },
                            }}
                        >
                            {breed.Name}
                        </Typography>

                        <Typography
                            variant='subtitle1'
                            paddingTop={1}
                            paddingBottom={3}
                            textTransform='uppercase'
                            color={colors.palette.text.secondary}
                            letterSpacing='1px'
                            fontWeight={550}
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    fontSize: '1.1rem'
                                },
                                [theme.breakpoints.between('sm', 'lg')]: {
                                    fontSize: '.9rem'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    fontSize: '.6rem'
                                },
                                opacity: '60%'
                            }}
                        >
                            Deer
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            // image size on screens below small
                            [theme.breakpoints.down('sm')]: {
                                height: 'auto',
                                borderRadius: '36px'
                            },
                            [theme.breakpoints.up('sm')]: {
                                height: 'auto',
                                borderRadius: '36px'
                            },
                            borderRadius: '64px',
                            display: 'block',
                            overflow: 'hidden'
                        }}
                    >
                        {imageList.length > 1 ?
                            <Carousel imageList={imageList}></Carousel>
                            : imageList.length !== 0 && <img src={imageList[0].URL}
                                alt={imageList[0].Caption}
                                draggable='false'
                                width='100%'
                                height='100%'
                                style={{
                                    objectFit: 'cover',
                                    aspectRatio:'3/2',
                                }}>
                            </img>
                        }
                    </Box>
                </Grid>

                {/* Right side of page */}
                <Grid
                    item
                    md={5}
                    justifyContent="start"
                    sx={{
                        [theme.breakpoints.only('md')]: {
                            width: '60vw',
                            marginTop: '2vh'
                        },
                    }}
                >
                    {breed.Description !== null && <Box sx={{
                        [theme.breakpoints.up('md')]: {
                            marginTop: 20
                        }
                    }}>
                        <Typography
                            variant='h4'
                            fontWeight={500}
                            paddingTop={3}
                            sx={{
                                opacity: '100%',
                                color: colors.palette.text.secondary,
                                lineHeight: '2.2rem'
                            }}
                        >
                            About the specie
                        </Typography>
                        <Typography
                            variant='body1'
                            component='div'
                            marginTop='1.2rem'
                            sx={{
                                color: colors.palette.text.secondary,
                                fontWeight: 'lighter',
                                opacity: '80%'
                            }}
                        >
                            {breed.Description}
                        </Typography>
                    </Box>}

                    <Box pt={4}>
                        <a href="mailto:info@highcsranch.com" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: colors.palette.text.secondary,
                                    color: colors.palette.text.primary,
                                    '&:hover': {
                                        backgroundColor: colors.palette.secondary.light,
                                        color: colors.palette.primary.main,
                                    }
                                }}
                                endIcon={<MailOutlineIcon />}
                            >
                                <Typography variant='caption' sx={{
                                    fontSize: '1rem',
                                    pr: .4
                                }}>
                                    Contact us
                                </Typography>
                            </Button>
                        </a>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}