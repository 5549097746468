import { BlobServiceClient } from '@azure/storage-blob';
var uuid = require("uuid");

// gets SAS key from the API so we can upload images with it
async function getSASKey() {
  const username = sessionStorage.getItem('username').slice(1, -1);
  const password = sessionStorage.getItem('password').slice(1, -1);

  let response = await fetch('https://hcrapi.azurewebsites.net/sas',
    {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body:
        JSON.stringify({
          username,
          password
        })
    })

  let data = response.text();
  return await data;
};

async function resolveKey() {
  return await getSASKey()
}

const storageAccountName = "highcsimages";
// </snippet_package>

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return (!storageAccountName) ? false : true;
}
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
const getBlobsInContainer = async (containerClient, type) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${type}/${blob.name}`
    );
  }

  return returnedBlobUrls;
}
// </snippet_getBlobsInContainer>

// <snippet_createBlobInContainer>
const createBlobInContainer = async (containerClient, file) => {

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(file.name);

  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadData(file, options);
}
// </snippet_createBlobInContainer>

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file, ID, type, Caption) => {
  if (!file) return [];

  //rename file to unique ID
  var blob = file.slice(0, file.size, 'image/jpeg');
  var uid = uuid.v4().substring(0, 13);
  var newFile = new File([blob], ID + "-" + uid + '.jpeg', { type: 'image/jpeg' });

  var sasToken = await resolveKey()

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient = blobService.getContainerClient(type);
  await containerClient.createIfNotExists({
    access: 'container',
  });


  var postURL
  if (type === "ranch") {
    postURL = 'https://hcrapi.azurewebsites.net/images/add'
  }
  else if (type === "exoticbreeder") {
    postURL = 'https://hcrapi.azurewebsites.net/exotics/' + ID + '/images/add';
  }
  else if (type === "longhornroles") {
    postURL = 'https://hcrapi.azurewebsites.net/longhorns/roles/' + ID + '/images/add'
  }
  else if (type === "exotic") {
    postURL = 'https://hcrapi.azurewebsites.net/exotics/breeds/' + ID + '/images/add'
  }
  else {
    postURL = 'https://hcrapi.azurewebsites.net/' + type + 's/' + ID + '/images/add'
  }

  try {
    const URL = 'https://highcsimages.blob.core.windows.net/' + type + '/' + ID + '-' + uid + '.jpeg'

    const username = sessionStorage.getItem('username')?.slice(1, -1);
    const password = sessionStorage.getItem('password')?.slice(1, -1);

    fetch(postURL, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body:
        JSON.stringify({
          URL,
          Caption,
          Username: username,
          Password: password
        })
    }
    )
    console.log("Image URL addition successful")
  }
  catch (exc) {
    console.log("Image URL addition failed: " + exc.Message)
  }

  // upload file
  await createBlobInContainer(containerClient, newFile);

  // get list of blobs in container
  return getBlobsInContainer(containerClient, type);
};
// </snippet_uploadFileToBlob>

async function deleteBlobIfItExists(URL) {
  const blobNameandContainer = URL.slice(43);
  var blobName = blobNameandContainer.split('/')[1]
  var type = blobNameandContainer.split('/')[0]
  console.log(blobNameandContainer, blobName, type)

  var sasToken = await resolveKey()

  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );

  const containerClient = blobService.getContainerClient(type);

  const options = {
    deleteSnapshots: 'include' // or 'only'
  }

  // Create blob client from container client
  const blockBlobClient = await containerClient.getBlockBlobClient(blobName);

  await blockBlobClient.deleteIfExists(options);

  const username = sessionStorage.getItem('username')?.slice(1, -1);
  const password = sessionStorage.getItem('password')?.slice(1, -1);

  try {
    fetch("https://hcrapi.azurewebsites.net/images/remove", {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body:
        JSON.stringify({
          URL,
          Username: username,
          Password: password
        })
    }
    )
    console.log("Image URL removal successful")
  }
  catch (exc) {
    console.log("Image URL removal failed: " + exc.Message)
  }

  console.log(`Deleted blob ${blobName}`);
}

export { uploadFileToBlob, deleteBlobIfItExists };