import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Divider, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { theme } from '../../styles/theme';

export const HomeExoticsSection: React.FC = () => {

    const [image, setImage] = useState<{ URL: string, Caption: string }>();

    useEffect(() => {
        async function getImages() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/0/images`, { method: 'get' })
            let data = await response.json();
            setImage(await data[0]);
        };

        if (image === undefined) {
            getImages();
        }
    }, [image]);

    const [breeds, setBreeds] = useState<{ BreedID: number, Name: string, Description: string }[]>([])

    useEffect(() => {
        async function getBreed() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/`, { method: 'get' })
            let data = await response.json();
            setBreeds(data);
        };

        if (breeds.length === 0) {
            getBreed();
        }

    }, [breeds.length]);


    function exotics() {
        window.location.href = `/Exotics`
    }

    return (
        <Box pl={"12vw"} pr={"12vw"} pt={"15vh"} pb={"15vh"}
            sx={{
                backgroundColor: colors.palette.primary.light,
                [theme.breakpoints.down('sm')]: {
                    paddingTop: '12vh'
                }
            }}
        >
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
            >
                <Box>
                    <Typography
                        variant='h2'
                        paddingBottom={'2rem'}
                        sx={{
                            color: colors.palette.text.primary,
                            textAlign: 'center'
                        }}
                    >
                        Exotics
                    </Typography>
                    <Divider sx={{
                        width: '10vw',
                        backgroundColor: colors.palette.text.primary,
                        opacity: '80%',
                        margin: 'auto',
                        [theme.breakpoints.up('sm')]: {
                            marginBottom: '4rem'
                        },
                        [theme.breakpoints.down('sm')]: {
                            marginBottom: '2rem'
                        }
                    }} />
                </Box>
                <Box sx={{
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <span onClick={() => exotics()}>
                        <Card
                            variant='outlined'
                            sx={{
                                gap: 2,
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    width: '80vw'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '76vw'
                                },
                            }}
                        >
                            <Box sx={{
                                [theme.breakpoints.up('md')]: {
                                    padding: '3rem'
                                },
                                [theme.breakpoints.down('md')]: {
                                    padding: '2rem'
                                },
                            }}>
                                <Typography
                                    variant='h4'
                                    component='div'
                                >
                                    Exotics
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    marginTop='.8rem'
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: '24vw',
                                            fontSize: '1.6rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '48vw',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: 'auto'
                                        },
                                    }}
                                >

                                    <>
                                        Our {breeds.length} breeds currently offered are {breeds.map(({ BreedID, Name }, index) => (
                                            <span key={index}>
                                                <span>{index + 1 === breeds.length && " and "}</span>
                                                <a href={"/Exotics/" + BreedID} style={{ textDecoration: 'none', color: 'inherit', fontWeight: 'bold' }}>{Name}{index + 1 < breeds.length ? ", " : "."}</a>
                                            </span>
                                        ))}
                                    </>
                                </Typography>
                                <Box
                                    mt={'1rem'}
                                    ml={'-.4rem'}
                                    sx={{ opacity: '60%' }}
                                >
                                    <Button
                                        variant='text'
                                        endIcon={<CallMadeIcon sx={{
                                            color: colors.palette.text.primary
                                        }} />}
                                    >
                                        <Typography variant='cardButton'>
                                            See exotics
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.between('md', 'xl')]: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end'
                                    },
                                }}
                            >
                                {image?.URL !== undefined && <CardContent>
                                    <CardMedia
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                height: '340px',
                                                width: '35vw'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                height: '30vh',
                                                width: '100%'
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                height: '210px'
                                            }
                                        }}
                                        component='img'
                                        width='auto'
                                        image={image?.URL}
                                        alt={image?.Caption}
                                    />
                                </CardContent>}
                            </Box>
                        </Card>

                    </span>
                </Box>
            </Box>
        </Box>
    )
}