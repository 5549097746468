import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import { useParams } from "react-router-dom";
import useToken from "../../components/Admin/SendLogin";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../styles/colors";
import CallMadeIcon from "@mui/icons-material/CallMade";

export const AdminTipMeasure: React.FC = () => {

    const params = useParams();
    var longhornID = 0;
    if (params.id !== undefined) {
        longhornID = parseInt(params.id);
    }

    const [measures, setMeasures] = useState<{ MeasurementID: number, LonghornID: number, Length: string, Date: string }[]>([]);

    useEffect(() => {
        async function getLonghorn() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/tipmeasures/`, { method: 'get' })
            let data = await response.json();
            setMeasures(data);
        };

        if (measures.length === 0) {
            getLonghorn();
        }

    }, [measures.length, longhornID, measures]);

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} token={""} />
    }

    function deleteMeasure(e: any) {
        if (window.confirm("Are you sure you want to delete this tip measurement record? This action cannot be undone.")) {
            var measureID = e.target.getAttribute("data-measureid")

            const username = sessionStorage.getItem('username')?.slice(1, -1);
            const password = sessionStorage.getItem('password')?.slice(1, -1);

            try {
                fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/tipmeasures/${measureID}/remove`, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body:
                        JSON.stringify({
                            Username: username,
                            Password: password
                        })
                })

                var div = document.getElementById(measureID)
                if (div) {
                    div.style.display = "none";
                }

                console.log("Measure with ID " + measureID + " marked as archived")
            }
            catch {
                console.log("An error has occurred")
            }
        }
    }

    return (
        <>
            <Navbar />
            <Box sx={{
                backgroundColor: colors.palette.background.default,
                color: colors.palette.text.secondary
            }}>
                <Box
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            paddingLeft: '12vw',
                            paddingRight: '12vw',
                            paddingTop: '4vh',
                            paddingBottom: '4vh'
                        },
                        [theme.breakpoints.down('md')]: {
                            paddingLeft: '12vw',
                            paddingRight: '12vw',
                            paddingTop: '4vh',
                            paddingBottom: '3vh'
                        }
                    }}>
                    <Box sx={{ paddingBottom: '8vh' }}>
                        <Button startIcon={<ChevronLeft />} variant='text' href='/Admin/Longhorns' sx={{ color: colors.palette.primary.light }}>
                            Go Back
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'start'
                            },
                            marginBottom: 5
                        }}
                    >
                        <Typography
                            color={colors.palette.text.secondary}
                            sx={{
                                opacity: '100%',
                                [theme.breakpoints.down('md')]: {
                                    justifyContent: 'start',
                                    width: '100%'
                                }
                            }}
                            variant='h3'
                            fontWeight={600}
                        >
                            {longhornID ? ('Edit Longhorn ' + longhornID + "'s Information") : ('Edit Longhorn Information')}
                        </Typography>
                    </Box>
                    <Box 
                    display={'flex'} 
                    justifyContent={'center'} 
                    mb={8}
                    sx={{
                    [theme.breakpoints.down('md')]: {
                        justifyContent: 'start'
                    }
                    }}
                    >
                    <Button 
                        sx={{
                            color: colors.palette.primary.light
                        }} 
                        variant="text"
                        endIcon={<CallMadeIcon />} 
                        href={`/Admin/Longhorns/${longhornID}/tipmeasures/0`}
                        >
                            Create New
                        </Button>
                    </Box>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        marginBottom={20}
                        sx={{
                            [theme.breakpoints.down('md')]: {
                            justifyContent: 'start'
                        }}}
                    >
                        <TableContainer sx={{ width: '30rem' }} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ opacity: '60%' }}>Measurement</TableCell>
                                        <TableCell sx={{ opacity: '60%' }} align="left">Length</TableCell>
                                        <TableCell sx={{ opacity: '60%' }} align="left">Date</TableCell>
                                    </TableRow>
                                </TableHead>
                                {measures.map(({ MeasurementID, Length, Date }, index) => (
                                    <TableBody key={index} id={MeasurementID.toString()}>
                                        <TableCell>{Length}</TableCell>
                                        <TableCell>{Date.split('T')[0]}</TableCell>
                                        <TableCell><Button data-measureid={MeasurementID} onClick={deleteMeasure} sx={{ color: colors.palette.primary.light }}>Delete</Button></TableCell>
                                    </TableBody>
                                ))}
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </>
    )
}