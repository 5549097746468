import AdminImageUploadUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { useParams } from "react-router-dom";
import AdminLonghornRoleForm from "../../components/Admin/AdminLonghornRoleForm";
import { Box, Button, Typography } from "@mui/material";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../styles/colors";

export const AdminLonghornRoleEdit: React.FC = () => {

  const params = useParams();
  var roleID = 0;
  if (params.id !== undefined) {
    roleID = parseInt(params.id);
  }

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/${roleID}/images`, { method: 'get' })
      let data = await response.json();
      setImageList(await data);
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length, roleID]);

  const [role, setRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })

  useEffect(() => {
    async function getLonghorn() {
      if (roleID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/${roleID}`, { method: 'get' })
        let data = await response.json();
        setRole(data[0]);
      }
    };

    if (role.Name === '') {
      getLonghorn();
    }

  }, [role.Name, roleID]);

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  return (
    <>
      <Navbar />
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '4vh'
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '3vh'
          }
        }}>
        <Box sx={{ paddingBottom: '8vh' }}>
          <Button startIcon={<ChevronLeft />} variant='text' href='/Admin/Longhorns/Roles' sx={{ color: colors.palette.primary.light }}>
            Go Back
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'start'
            }
          }}
        >
          <Typography
            color={colors.palette.text.secondary}
            sx={{
              opacity: '100%',
              [theme.breakpoints.down('md')]: {
                justifyContent: 'start',
                width: '100%'
              }
            }}
            variant='h3'
            fontWeight={600}
          >
            Representative {role.Name}
          </Typography>
        </Box>
      </Box>
      <AdminImageUploadUI id={roleID} type="longhornroles" title="Upload image" imageList={imageList}></AdminImageUploadUI>
      <AdminLonghornRoleForm {...role}></AdminLonghornRoleForm>
    </>
  )
}