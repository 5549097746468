import Navbar from "../../components/Global/Navbar/Navbar"
import React from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import { colors } from "../../styles/colors";
import CallMadeIcon from "@mui/icons-material/CallMade";

export const AdminLonghornRole: React.FC = () => {

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} token={""} />
    }

    return (
        <>
            <Navbar />
            <Box sx={{
                backgroundColor: colors.palette.background.default
            }}>
                <Box sx={{
                    backgroundColor: colors.palette.background.default
                }}>
                    <Box
                        sx={{
                            [theme.breakpoints.up('md')]: {
                                paddingLeft: '12vw',
                                paddingRight: '12vw'
                            },
                            [theme.breakpoints.down('md')]: {
                                paddingLeft: '12vw',
                                paddingRight: '12vw'
                            }
                        }}
                    >
                        <Box
                            sx={{
                                [theme.breakpoints.up('md')]: {
                                    paddingTop: '4vh',
                                    paddingBottom: '4vh'
                                },
                                [theme.breakpoints.down('md')]: {
                                    paddingTop: '4vh',
                                    paddingBottom: '3vh'
                                }
                            }}
                        >
                            <Box sx={{ paddingBottom: '8vh' }}>
                                <Button startIcon={<ChevronLeft />} variant='text' href='/Admin/Longhorns' sx={{ color: colors.palette.primary.light }}>
                                    Go Back
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    [theme.breakpoints.down('sm')]: {
                                        justifyContent: 'start'
                                    }
                                }}
                            >
                                <Typography
                                    color={colors.palette.text.secondary}
                                    sx={{
                                        opacity: '100%',
                                        [theme.breakpoints.down('md')]: {
                                            justifyContent: 'start',
                                            width: '100%'
                                        }
                                    }}
                                    variant='h3'
                                    fontWeight={600}
                                >
                                    Edit Longhorn Role Representatives
                                </Typography>
                            </Box>
                        </Box>

                        <Box>
                            <Typography
                                variant='body1'
                                width={'100%'}
                                textAlign='center'
                                color={colors.palette.text.secondary}
                                sx={{
                                    opacity: '80%',
                                    [theme.breakpoints.down('md')]: {
                                        textAlign: 'start',
                                        paddingBottom: 1,
                                        fontSize: '1rem'
                                    }
                                }}
                            >
                                Edit general information for longhorn roles
                            </Typography>

                            <Typography
                                variant='body2'
                                width={'100%'}
                                color={colors.palette.text.secondary}
                                sx={{
                                    opacity: '40%',
                                    paddingTop: '4px',
                                    textAlign: 'center',
                                    fontWeight: 400,
                                    [theme.breakpoints.down('md')]: {
                                        textAlign: 'start'
                                    }
                                }}
                            >
                                If you are wanting to edit the description or image for a specific longhorn, visit <Link style={{color: colors.palette.primary.main}} to="/Admin/Longhorns">here</Link>
                            </Typography>
                        </Box>

                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '6vw',
                        paddingBottom: '13vw'
                    },
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '6vw',
                        paddingBottom: '6vw'
                    }
                }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
                >
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={5}
                    >
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Longhorns/Roles/1" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Bull
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Longhorns/Roles/2" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Steer
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Longhorns/Roles/3" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Cow
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Longhorns/Roles/4" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Heifer
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}