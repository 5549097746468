import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import CallMadeIcon from "@mui/icons-material/CallMade";

export const AdminAffiliations: React.FC = () => {

  const [affiliations, setAffiliations] = useState<({ AffiliationID: number, Name: string })[]>([])

  useEffect(() => {
    async function getAffs() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/affiliations`, { method: 'get' })
      let data = await response.json();
      setAffiliations(data);
    };

    if (affiliations.length === 0) {
      getAffs();
    }
  }, [affiliations.length]);

  const username = sessionStorage.getItem('username')?.slice(1, -1);
  const password = sessionStorage.getItem('password')?.slice(1, -1);

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  function deleteAffiliation(e: any) {
    if (window.confirm("Are you sure you want to delete this affiliation? This action cannot be undone.")) {
      var affID = e.target.getAttribute("data-affiliationid")
      try {
        fetch(`https://hcrapi.azurewebsites.net/affiliations/${affID}/remove`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              Username: username,
              Password: password
            })
        })

        var div = document.getElementById(affID)
        if (div) {
          div.style.display = "none";
        }
      }
      catch {
        console.log("An error has occurred")
      }

    }

  }

  return (
    <>
      <Navbar />
      <Box sx={{
        backgroundColor: colors.palette.background.default
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              Edit Affiliations
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '4vh',
            textAlign: 'center'
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '3vh',
            textAlign: 'start'
          }
        }}
      >
        <Button variant='text'
          endIcon={<CallMadeIcon />}
          href="/Admin/Affiliations/0" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
          Add New
        </Button>
      </Box>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: 0
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: 2
          }
        }}
        marginBottom={20}
      >
        {affiliations && affiliations.map(({ AffiliationID, Name }: any) => (
          <Box id={AffiliationID} key={AffiliationID}
            sx={{
              [theme.breakpoints.up('md')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                textAlign: 'center',
                marginBottom: 3
              },
              [theme.breakpoints.down('md')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                textAlign: 'start',
                marginBottom: 3
              },
              display: 'flex',
              flexDirection: 'column',
              gap: .2
            }}
          >
            <Box>
              <Typography variant='body1' sx={{ color: colors.palette.text.secondary }}>{Name}</Typography>
            </Box>
            <Box>
              <Button variant="text" key={"btn" + AffiliationID} data-affiliationid={AffiliationID} onClick={deleteAffiliation} sx={{ color: colors.palette.text.secondary, opacity: '80%' }}>Delete</Button>
              <Button variant="text" href={`/Admin/Affiliations/${Number(AffiliationID)}`} key={AffiliationID} sx={{ color: colors.palette.primary.light }}> Edit </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}