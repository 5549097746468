import React from "react";
import { Box, Typography, Link } from '@mui/material';
import { colors } from "../../../styles/colors";
import { theme } from "../../../styles/theme";

export const Footer: React.FC = () => {

    return (
        <Box sx={{
            backgroundColor: colors.palette.secondary.light
        }}>
            <Box
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '8vw',
                        paddingBottom: '8vw'
                    },
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '24vw',
                        paddingBottom: '24vw'
                    }
                }}
            >
                <Typography variant='h3' sx={{
                    typography: {
                        fontWeight: 600,
                        letterSpacing: '-.03rem'
                    }
                }}>
                    Contact us
                </Typography>
                <Typography
                    variant='subtitle1'
                    sx={{
                        marginTop: '2rem',
                        typography: {
                            width: '40vw',
                            fontSize: '1.4rem',
                            opacity: '60%',
                            letterSpacing: '-.02rem',
                            textDecoration: 'underline'
                        }
                    }}>
                    <Link href="mailto:info@highcsranch.com" style={{ textDecoration: 'none', color: 'inherit' }}>info@highcsranch.com</Link>
                </Typography>

                <Typography
                    variant='subtitle1'
                    sx={{
                        marginTop: '.6rem',
                        typography: {
                            width: '40vw',
                            fontSize: '1.4rem',
                            opacity: '60%',
                            letterSpacing: '-.02rem'
                        }
                    }}>
                    Landline:
                    <Link href="tel:(830) 995-5324" style={{paddingLeft: '4px', textDecoration: 'underline', color: 'inherit'}}>(830) 995-5324</Link>
                </Typography>

                <Typography
                    variant='subtitle1'
                    sx={{
                        marginTop: '3rem',
                        typography: {
                            fontSize: '1.4rem',
                            opacity: '60%',
                            letterSpacing: '-.02rem',
                            lineHeight: '1.8rem'
                        }
                    }}>
                    Roger and Cynthia Cagle <br/>
                    145 Lange Lane <br />
                    Comfort, TX 78013
                    <br />
                    <Link sx={{ textDecoration: 'underline', color: 'inherit' }} href="https://maps.google.com/?q=145 Lange Lane Comfort, TX 78013">Open in Google Maps</Link>
                    <br />
                    <Link sx={{ textDecoration: 'underline', color: 'inherit' }} href="http://maps.apple.com/?q=145 Lange Lane Comfort, TX 78013">Open in Apple Maps</Link>
                </Typography>

                <Typography
                    variant='subtitle1'
                    sx={{
                        marginTop: '3rem',
                        typography: {
                            fontSize: '1.4rem',
                            opacity: '60%',
                            letterSpacing: '-.02rem',
                            lineHeight: '1.8rem'
                        }
                    }}>
                    A site by Seth Nall & Luke Arnould
                </Typography>
            </Box>
        </Box >
    )
}