import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { colors } from '../../../styles/colors';
import { useState } from 'react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type props = {
  imageList: Array<{ URL: string, Caption: string }>
}

export function Carousel(props: props) {

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = props.imageList.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

    return (
      <Box sx={{ justifyContent: 'center', flexGrow: 1 }}>
        <Paper />
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={5000}
        >
          {props.imageList.map(({URL, Caption}, index) => (
            <div key={index}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component='img'
                  draggable='false'
                  sx={{
                    objectFit: 'cover',
                    color: colors.palette.text.secondary,
                    textAlign: 'center',
                    // image size on screens below small
                    [theme.breakpoints.down('sm')]: {
                      height: '240px',
                      borderRadius: '36px'
                    },
                    borderRadius: '64px',
                    display: 'block',
                    overflow: 'hidden',
                    width: '100%',
                    aspectRatio:'3/2'
                  }}
                  src={URL}
                  alt={Caption}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          sx={{ bgcolor: 'transparent' }}
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
              sx={{
                color: colors.palette.primary.main,
                '&:hover': {
                  color: colors.palette.primary.main
                }
              }}
            >
              {/* Next */}
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
              sx={{
                color: colors.palette.primary.main,
                '&:hover': {
                  color: colors.palette.primary.main
                }
              }}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {/* Back */}
            </Button>
          }
        />
      </Box>
    );
  }