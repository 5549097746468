import Navbar from "../components/Global/Navbar/Navbar"
import { LonghornsWrapper } from "../components/RegisteredLonghorns/LonghornsWrapper"
import { LonghornsSection } from "../components/RegisteredLonghorns/LonghornsSection"
import { Footer } from "../components/Global/Footer/Footer"

export const RegisteredLonghorns: React.FC = () => {
    
    return (
        <>
            <Navbar />
            <LonghornsWrapper /> 
            <LonghornsSection />
            <Footer />
        </>
    )
}