import React, { useLayoutEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { theme } from '../../styles/theme';

type props = {
    filter: string
}

export default function LonghornsStockSection(props: props) {

    const [longhorns, setLonghorns] = useState<{ LonghornID: number, RanchPrefix: string, Name: string, RoleID: number, Description: string, URL: string, Caption: string }[]>([])

    useLayoutEffect(() => {
        async function getLonghorns() {
            var getURL = `https://hcrapi.azurewebsites.net/longhorns/images`
            if (props.filter === "cows" || props.filter === "bulls" || props.filter === "steers" || props.filter === "heifers") {
                getURL = `https://hcrapi.azurewebsites.net/longhorns/list/${props.filter}/images`
            }

            let response = await fetch(getURL, { method: 'get' })
            let data = await response.json();
            setLonghorns(data);
        };

        if (longhorns.length === 0) {
            getLonghorns();
        }
    }, [longhorns.length, props.filter]);

    if (props.filter !== "cows" && props.filter !== "bulls" && props.filter !== "steers" && props.filter !== "heifers" && props.filter !== "") {
        window.location.href = "/Longhorns/List"
    }

    return (
        <Box pl={"12vw"} pr={"12vw"} pt={"4vh"} pb={"15vh"}
            sx={{
                backgroundColor: colors.palette.background.default,
            }}
        >
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
            >

                {/* CARD 1 STARTS HERE */}
                {longhorns.map(({ LonghornID, RanchPrefix, RoleID, Name, Description, URL, Caption }, index) => (
                    <Box
                        key={index}
                        sx={{
                            padding: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                        <a href={`/Longhorns/${LonghornID}`} style={{
                            textDecoration: 'none',
                            borderColor: 'none'
                        }}>
                            <Card
                                variant='outlined'
                                sx={{
                                    gap: 2,
                                    [theme.breakpoints.up('md')]: {
                                        display: 'flex',
                                        width: '76vw'
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        width: '76vw'
                                    },
                                }}
                            >
                                <Box sx={{
                                    [theme.breakpoints.up('md')]: {
                                        padding: '3rem'
                                    },
                                    [theme.breakpoints.down('md')]: {
                                        padding: '2rem'
                                    },
                                }}>
                                    <Typography
                                        variant='h4'
                                        component='div'
                                    >
                                        {RanchPrefix} {Name}
                                    </Typography>
                                    <Typography
                                        variant='subtitle1'
                                        component='div'
                                        marginTop='.8rem'
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                width: '24vw',
                                                fontSize: '1.6rem'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                width: '48vw',
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                width: 'auto'
                                            },
                                        }}
                                    >
                                        {Description}
                                    </Typography>
                                    <Box
                                        mt={'1rem'}
                                        ml={'-.4rem'}
                                        sx={{ opacity: '60%' }}
                                    >
                                        <Button
                                            variant='text'
                                            endIcon={<CallMadeIcon sx={{
                                                color: colors.palette.text.primary
                                            }} />}
                                        >
                                            <Typography variant='cardButton'>
                                                {Name}
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        [theme.breakpoints.between('md', 'xl')]: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'end'
                                        },
                                    }}
                                >
                                    {URL && <CardContent>
                                        <CardMedia
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    height: '340px',
                                                    width: '35vw'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    height: '30vh',
                                                    width: '100%'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    height: '210px'
                                                }
                                            }}
                                            component='img'
                                            width='100%'
                                            image={URL}
                                            alt={Caption}
                                        />
                                    </CardContent>}

                                </Box>
                            </Card>
                        </a>
                    </Box>
                ))}

            </Box>
        </Box>
    )
}