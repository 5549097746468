import Navbar from "../components/Global/Navbar/Navbar"
import { AffiliationsWrapper } from "../components/Affiliations/AffiliationsWrapper"
import { Footer } from "../components/Global/Footer/Footer"

export const Affiliations: React.FC = () => {
    return (
        <>
            <Navbar />
            <AffiliationsWrapper/> 
            <Footer />  
        </>
    )
}