import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  URL: {
    [theme.breakpoints.up('md')]: {
      objectFit: 'cover',
      height: '30vh',
      width: '90%',
      borderRadius: '36px',
    },
    [theme.breakpoints.down('md')]: {
      objectFit: 'cover',
      height: '30vh',
      width: '60vw',
      borderRadius: '36px',
    },
  },
  Caption: {
    width: '100%',
    textAlign: 'center',
    variant: 'body1',
    color: colors.palette.text.secondary,
    marginBottom:'2rem !important',
    paddingTop: '1rem',
    opacity: '60%',
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'start',
      paddingLeft:'1rem',
      marginBottom:'1rem !important',
    }
  }
});

type OtherDenizensProps = {
  imageList: {URL: string, Caption: string}[];
}

export const OtherDenizensWrapper: React.FC<OtherDenizensProps> = (props) => {

  const classes = useStyles();

  return (
    <Box sx={{ backgroundColor: colors.palette.background.default }}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          textAlign='center'
          variant='h2'
          pt={10}
          sx={{
            color: colors.palette.text.secondary
          }}
        >
          Other Denizens
        </Typography>
      </Box>

      <Grid
        container spacing={'2vh'}
        pl={'12vw'} pr={'12vw'}
        sx={{
          display: 'flex',
          [theme.breakpoints.up('md')]: {
            paddingTop: '10vh',
            paddingBottom: '24vh',
            justifyContent: 'space-between'
          },
          [theme.breakpoints.down('md')]: {
            paddingTop: '2vh',
            paddingBottom: '12vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          },
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >

        {props.imageList.map(({URL, Caption}, index) => (
          <Grid item md={6} key={index}>
            <Box
              pt={2}
              display='flex'
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
            >
              <Box 
              component='img' 
              draggable='false' 
              sx={{
                objectFit: 'cover',
                // image size on screens below small
                [theme.breakpoints.down('sm')]: {
                    borderRadius: '36px',
                    width:'60vw'
                },
                [theme.breakpoints.up('md')]: {
                    borderRadius: '36px',
                    width:'30vw'
                },
                borderRadius: '64px',
                display: 'block',
                overflow: 'hidden',
                width: '50vw',
                aspectRatio: '3/2'
            }}
                src={URL}
                alt={Caption} />
              <Typography
                className={classes.Caption}
              >
                {Caption}
              </Typography>
            </Box>
          </Grid>
        ))}

      </Grid>
    </Box>
  );
}