import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Divider, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { theme } from '../../styles/theme';

export const ExoticsSection: React.FC = () => {

    const [breeds, setBreeds] = useState<({ BreedID: number, Name: string, Description: string, URL: string, Caption: string }[])>([])

    useEffect(() => {
        async function getBreeds() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/images`, { method: 'get' })
            let data = await response.json();
            setBreeds(data);
        };

        if (breeds.length === 0) {
            getBreeds();
        }
    }, [breeds.length]);

    return (
        <Box pl={"12vw"} pr={"12vw"} pt={"4vh"} pb={"15vh"}
            sx={{
                backgroundColor: colors.palette.primary.light,
            }}
        >
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                pt='4vw'
            >
                <Box>
                    <Typography
                        variant='h2'
                        paddingBottom={'2rem'}
                        sx={{
                            color: colors.palette.text.primary,
                            textAlign: 'center'
                        }}
                    >
                        Species
                    </Typography>
                    <Divider sx={{
                        width: '10vw',
                        backgroundColor: colors.palette.text.primary,
                        margin: 'auto',
                        marginBottom: '4rem'
                    }} />
                </Box>

                {breeds.map(({ BreedID, Name, Description, URL, Caption }, index) =>
                (
                    <div key={index}>
                        <Box sx={{
                            padding: '1rem',
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <a href={`/exotics/${BreedID}`} style={{
                                textDecoration: 'none',
                                borderColor: 'none'
                            }}>
                                <Card
                                    variant='outlined'
                                    sx={{
                                        gap: 2,
                                        [theme.breakpoints.up('md')]: {
                                            display: 'flex',
                                            width: '76vw'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '76vw'
                                        },
                                    }}
                                >
                                    <Box sx={{
                                        [theme.breakpoints.up('md')]: {
                                            padding: '3rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            padding: '2rem'
                                        },
                                    }}>
                                        <Typography
                                            variant='h4'
                                            component='div'
                                        >
                                            {Name}
                                        </Typography>
                                        <Typography
                                            variant='subtitle1'
                                            component='div'
                                            marginTop='.8rem'
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    width: '24vw',
                                                    fontSize: '1.6rem'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    width: '48vw',
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    width: 'auto'
                                                },
                                            }}
                                        >
                                            {Description.slice(0, 200)} ...
                                        </Typography>
                                        <Box
                                            mt={'1rem'}
                                            ml={'-.4rem'}
                                            sx={{ opacity: '60%' }}
                                        >
                                            <Button
                                                variant='text'
                                                endIcon={<CallMadeIcon sx={{
                                                    color: colors.palette.text.primary
                                                }} />}
                                            >
                                                <Typography variant='cardButton'>
                                                    See {Name}
                                                </Typography>
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            [theme.breakpoints.between('md', 'xl')]: {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'end'
                                            },
                                        }}
                                    >
                                        {URL &&
                                            <CardContent>
                                                <CardMedia
                                                    sx={{
                                                        [theme.breakpoints.up('md')]: {
                                                            height: '340px',
                                                            width: '35vw'
                                                        },
                                                        [theme.breakpoints.down('md')]: {
                                                            height: '30vh',
                                                            width: '100%'
                                                        },
                                                        [theme.breakpoints.down('sm')]: {
                                                            height: '210px'
                                                        }
                                                    }}
                                                    component='img'
                                                    width='auto'
                                                    image={URL}
                                                    alt={Caption}
                                                />
                                            </CardContent>}

                                    </Box>
                                </Card>
                            </a>
                        </Box>
                    </div>
                ))}
            </Box>
        </Box>
    )
}