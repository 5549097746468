import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const colors = createTheme({
    palette: {
        primary: {
            light: '#E7A97B',
            main: '#F08233',

        },
        secondary: {
            light: grey[200],
            main: grey[400],

        },
        background: {
            default: '#2C2C2C'
        },
        text: {
            primary: '#000',
            secondary: '#fff'
        },
    },
})