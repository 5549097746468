import React from 'react';
import { Grid, Box, Typography, Divider } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

export const AboutSection: React.FC = () => {

    return (
        <Box sx={{ backgroundColor: colors.palette.background.default }}>
            <Grid
                container spacing={6} justifyContent='space-between'
                pl={"12vw"} pr={"12vw"}
                sx={{
                    display: 'flex',
                    [theme.breakpoints.up('md')]: {
                        paddingTop: '12vh',
                        paddingBottom: '25vh'
                    },
                    [theme.breakpoints.down('md')]: {
                        paddingTop: '0vh',
                        paddingBottom: '15vh'
                    },
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <Grid item md={5} pb={5}
                >
                    <Box pt={2}>
                        <Typography
                            variant='h3'
                            sx={{
                                color: colors.palette.text.secondary,
                                fontWeight: 700,
                                opacity: '100%'
                            }}
                        >
                            Mission Statement
                        </Typography>

                        <Typography
                            variant='h5'
                            fontWeight={300}
                            paddingTop={3}
                            sx={{
                                color: colors.palette.text.secondary,
                                opacity: '70%',
                                lineHeight: '2.2rem',
                                [theme.breakpoints.up('lg')]: {
                                    width: '28vw'
                                },
                                [theme.breakpoints.between('md', 'lg')]: {
                                    width: '32vw',
                                },
                                [theme.breakpoints.up('sm')]: {
                                    width: '70vw'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    width: '70vw',
                                    fontSize: '1.04rem',
                                    lineHeight: '1.6rem'
                                },
                            }}
                        >
                            During our stewardship, we strive to provide a habitat that will foster the safety and development of all the species that reside herein.  Through this natural environment and providing supplementation as needed, we believe the result will be the development of some of the highest quality animals available anywhere.
                        </Typography>
                    </Box>
                </Grid>

                <Divider orientation='vertical' flexItem sx={{ height: 'auto', opacity: '60%', width: '2px' }} />

                <Grid item md={5} pb={5}
                >
                    <Box pt={2}>
                        <Typography
                            variant='h3'
                            sx={{
                                color: colors.palette.text.secondary,
                                fontWeight: 700,
                                opacity: '100%'
                            }}
                        >
                            Approach
                        </Typography>

                        <Typography
                            variant='h5'
                            fontWeight={300}
                            paddingTop={3}
                            sx={{
                                color: colors.palette.text.secondary,
                                opacity: '70%',
                                lineHeight: '2.2rem',
                                [theme.breakpoints.up('lg')]: {
                                    width: '28vw'
                                },
                                [theme.breakpoints.between('md', 'lg')]: {
                                    width: '32vw',
                                },
                                [theme.breakpoints.up('sm')]: {
                                    width: '70vw'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    width: '70vw',
                                    fontSize: '1.04rem',
                                    lineHeight: '1.6rem'
                                },
                            }}
                        >
                            Although we hold title to our property, in a sense, the land owns us.  We feel a deep desire, indeed almost an obligation, to improve and maximize its potential as a home for us, the native wildlife, registered longhorns and the multiple non-aggressive exotic species that inhabit it.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box >
    )
}