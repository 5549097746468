import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';
import { Carousel } from '../Global/Carousel/Carousel';

export const AboutWrapper: React.FC = () => {

    const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

    useEffect(() => {
        async function getImages() {
            let response = await fetch('https://hcrapi.azurewebsites.net/images', { method: 'get' })
            let data = await response.json();
            setImageList(data);
        };

        if (imageList.length === 0) {
            getImages();
        }

    }, [imageList.length]);

    return (
        <Box sx={{ backgroundColor: colors.palette.background.default }}>
            
            <Grid
                container spacing={6} justifyContent='space-between'
                pl={"12vw"} pr={"12vw"}
                sx={{
                    display: 'flex',
                    [theme.breakpoints.up('md')]: {
                        paddingTop: '18vh',
                        paddingBottom: '20vh'
                    },
                    [theme.breakpoints.down('md')]: {
                        paddingTop: '0vh',
                        paddingBottom: '12vh'
                    },
                    flexDirection: { xs: 'column', md: 'row' }
                }}
            >
                <Grid item md={6}
                >
                    <Box pt={4}>
                        <Typography
                            variant='h2'
                            sx={{
                                color: colors.palette.text.secondary,
                                [theme.breakpoints.up('lg')]: {
                                    fontSize: '4rem',
                                    width: '60%'
                                }
                            }}
                        >
                            About the ranch
                        </Typography>

                        <Typography
                            variant='h5'
                            fontWeight={300}
                            paddingTop={3}
                            sx={{
                                color: colors.palette.text.secondary,
                                opacity: '70%',
                                lineHeight: '2.2rem',
                                [theme.breakpoints.up('lg')]: {
                                    width: '28vw'
                                },
                                [theme.breakpoints.between('md', 'lg')]: {
                                    width: '32vw',
                                },
                                [theme.breakpoints.up('sm')]: {
                                    width: '70vw'
                                },
                                [theme.breakpoints.down('sm')]: {
                                    width: '70vw',
                                    fontSize: '1.04rem',
                                    lineHeight: '1.6rem'
                                },
                            }}
                        >
                            For most of our lives together we have traveled and worked internationally, with London, England being our primary home base for over 20 years.  When it came time for us to consider retirement and the next phase of our lives, we looked for a change of pace offering us a deeper connection to our environment.
                            <br/>
                            <br/>
                            The search for our “forever” home was broad, encompassing many international locations as well as several here in the United States, where we always considered home.  The beautiful Hill Country of Texas, generally ranging from north of San Antonio to south of Austin, with its variation in landscapes and abundance of friendly folks finally emerged as the leading candidate.
                            <br/>
                            <br/>
                            Thanks to the help of friends in the region, we found and acquired our approximately 206 acre initial footprint of property located between Comfort and Center Point in 2003. Since then, through 2020, we have added four adjacent properties as they became available leading to the current High C’s Ranch. Our property is blessed with live water, an abundance of hardwoods, some of the highest elevation in Kerr County and wonderful topographical variation. </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    md={6}
                    justifyContent="center"
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            width: '60vw',
                            marginTop: '4vh'
                        },
                        [theme.breakpoints.only('md')]: {
                            width: '60vw',
                            marginTop: '2vh'
                        },
                    }}
                >
                    {imageList.length > 0 && <Carousel imageList={imageList} />}
                </Grid>
            </Grid>
        </Box >
    )
}