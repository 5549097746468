import Navbar from "../../components/Global/Navbar/Navbar"
import React from 'react';
import Login from "../../components/Admin/Login";
import { useParams } from "react-router-dom";
import useToken from "../../components/Admin/SendLogin";
import AdminTipMeasureForm from "../../components/Admin/AdminTipMeasureForm";

export const AdminTipMeasureEdit: React.FC = () => {

    const params = useParams();
    var longhornID = 0;
    var measureID = 0;
    if (params.id !== undefined) {
        longhornID = parseInt(params.id);
    }
    if (params.measureid !== undefined) {
        measureID = parseInt(params.measureid)
    } 

    const { token, setToken } = useToken();
    
    if (!token) {
        return <Login setToken={setToken} token={""} />
    }

    return (
        <>
            <Navbar />
            <AdminTipMeasureForm MeasurementID={measureID} longhornID={longhornID}></AdminTipMeasureForm>
        </>
    )
}