import { alpha, createTheme } from "@mui/material";
import { colors } from "./colors";


declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        navButtons: true;
        mobileNavButtons: true;
        contained_uploadUI: true;
    }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        mobileNavMenu: true;
        cardButton: true;
        subtitle2alt: true;
        chipLabel: true;
    }
}

export const theme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {

                },
                contained: {
                    borderRadius: '64px',
                    paddingRight: '3rem',
                    paddingLeft: '3rem',
                    height: '56px',
                    textTransform: 'initial',
                    backgroundColor: colors.palette.primary.main,
                    color: colors.palette.text.primary,
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: colors.palette.secondary.light,
                        color: colors.palette.primary.main,
                        boxShadow: 'none',
                    },
                },
                outlined: {

                },
                text: {
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: colors.palette.text.secondary,
                        opacity: '60%',
                        boxShadow: 'none'
                    },
                }
            },
            variants: [
                {
                    props: { variant: 'navButtons' },
                    style: {
                        color: colors.palette.primary.light,
                        display: 'block',
                        '&:hover': {
                            backgroundColor: colors.palette.background.default,
                            color: colors.palette.text.secondary
                        }
                    },
                },
                {
                    props: { variant: 'mobileNavButtons' },
                    style: {
                        display: 'block',
                        disableTouchRipple: 'true',
                        color: colors.palette.text.secondary,
                        '&:hover': {
                            color: colors.palette.primary.main
                        }
                    },
                },
                {
                    props: { variant: 'contained_uploadUI' },
                    style: {
                        borderRadius: '64px',
                        paddingRight: '3rem',
                        paddingLeft: '3rem',
                        height: '48px',
                        textTransform: 'initial',
                        backgroundColor: colors.palette.text.secondary,
                        color: colors.palette.text.primary,
                        boxShadow: 'none',
                        opacity: '80%',
                        '&:hover': {
                            backgroundColor: colors.palette.text.primary,
                            color: colors.palette.text.secondary,
                            boxShadow: 'none',
                        },
                    }
                }
            ]
        },
        MuiDrawer: {
            styleOverrides: {
                root: {
                    backgroundColor: 'none',
                },
                paper: {
                    zIndex: 5,
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '6rem',
                    paddingTop: '.6rem',
                    boxShadow: 'none',
                    position: 'sticky'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    flexWrap: 'nowrap'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '32px',
                    borderColor: colors.palette.primary.main,
                    borderWidth: '2px',
                    gap: '1vw'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    ':last-child': {
                        paddingBottom: '0px'
                    }
                }
            }
        },
        MuiCardMedia: {
            styleOverrides: {
                root: {
                    borderRadius: '30px'
                }
            }
        },
        MuiMobileStepper: {
            styleOverrides: {
                dotActive: {
                    backgroundColor: colors.palette.primary.main
                },
                dot: {
                    backgroundColor: 'rgba(134, 134, 134, 0.87)'
                }
            },
        },
        MuiContainer: {
            styleOverrides: {
                // root: {
                //     marginLeft: '10vw',
                //     marginRight: '10vw',
                //     paddingTop: '20vh',
                //     paddingBottom: '20vh'
                // }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: colors.palette.primary.light
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: colors.palette.text.secondary
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    height: '3rem',
                    '&:before': { borderBottom: '2px solid #ffffff6a' },
                    // '&:hover' : { borderBottom: '2px solid' +  colors.palette.primary.light },
                    '&:after': {
                        borderBottom: '2px solid' + colors.palette.primary.main,
                    }
                }
            }
        },
        MuiTypography: {
            variants: [
                // Body1
                {
                    props: { variant: 'body1' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                        fontWeight: '100 !important',
                        fontSize: '1.3rem'
                    }
                },
                // Body2
                {
                    props: { variant: 'body2' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                        opacity: "60%"
                    }
                },
                // Button
                {
                    props: { variant: 'button' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                        textTransform: 'initial',
                        fontSize: '1rem',
                        fontWeight: 400,
                    }
                },
                // Caption
                {
                    props: { variant: 'caption' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                    }
                },
                // H1
                {
                    props: { variant: 'h1' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontWeight: 700
                    }
                },
                // H2
                {
                    props: { variant: 'h2' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontWeight: 700
                    }
                },
                // H3
                {
                    props: { variant: 'h3' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontWeight: 300,
                        opacity: '70%'
                    }
                },
                // H4
                {
                    props: { variant: 'h4' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontWeight: 700,
                        opacity: '80%'
                    }
                },
                // H5
                {
                    props: { variant: 'h5' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        opacity: '60%'
                    }
                },
                // H6
                {
                    props: { variant: 'h6' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                    }
                },
                // Inherit
                {
                    props: { variant: 'inherit' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                        opacity: '60%'
                    }
                },
                // Overline
                {
                    props: { variant: 'overline' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                    }
                },
                // Subtitle1
                {
                    props: { variant: 'subtitle1' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(','),
                        fontSize: '1.2rem !important',
                        fontWeight: 100,
                        lineHeight: '128%',
                        opacity: '80%'
                    }
                },
                // Subtitle2
                {
                    props: { variant: 'subtitle2' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontSize: '1rem',
                        letterSpacing: '.05rem'
                    }
                },
                // Subtitle2Alt
                {
                    props: { variant: 'subtitle2alt' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontSize: '1rem',
                        letterSpacing: '.05rem',
                        opacity: '60%'
                    }
                },
                // Mobile Nav Menu
                {
                    props: { variant: 'mobileNavMenu' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontSize: '1.4rem',
                        letterSpacing: '.05rem',
                        color: colors.palette.text.secondary
                    }
                },
                // Card Button
                {
                    props: { variant: 'cardButton' },
                    style: {
                        fontFamily: ['Hepta Slab', 'serif'].join(","),
                        fontSize: '.9rem',
                        textTransform: 'initial',
                        opacity: '90%',
                        color: colors.palette.text.primary,
                        fontWeight: 600
                    }
                },
                // Chip Label
                {
                    props: { variant: 'chipLabel' },
                    style: {
                        fontFamily: ['Roboto', 'sans-serif'].join(","),
                        fontSize: '1rem',
                        fontWeight: 'lighter',
                        opacity: '60%',
                        color: colors.palette.text.secondary
                    }
                },
            ]
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: 'none',
                    justifyContent: 'space-between'
                },
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.palette.secondary.light,
                    opacity: '60%'
                }
            }
        },
        MuiChip: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        width: '100%',
                        height: '3rem',
                        borderRadius: '36px',
                        opacity: '60%',
                        fontSize: '1rem',
                        color: colors.palette.text.secondary,
                        fontWeight: 'lighter',
                        cursor: 'pointer'
                    }
                }
            ]
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    opacity: '100%',
                    textDecoration: 'none',
                    '&:hover': {
                        opacity: '60%',
                    }
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(colors.palette.text.primary, .25),
                    borderRadius: '16px'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: '2px solid #ffffff3f',
                    color: colors.palette.text.secondary,
                    fontWeight: 'lighter'
                }
            }
        },
        MuiTablePagination: {
            styleOverrides: {
                root: {
                    color: colors.palette.text.secondary,
                    '.Mui-disabled': {
                        color: alpha(colors.palette.text.secondary, .6) + '!important',
                    }
                }
            }
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    paddingLeft: '36px',
                    paddingRight: '36px',
                    width: 'auto !important',
                    borderRadius: '36px',
                    textTransform: 'initial',
                    fontWeight: '400',
                    fontSize: '.9rem',
                    letterSpacing: 'none',
                    color: colors.palette.text.primary + '!important',
                    background: colors.palette.text.secondary + '!important'
                }
            }
        }
    },


    breakpoints: {
        values: {
            xs: 0,
            sm: 640,
            md: 1210,
            lg: 1528,
            xl: 4000
        },
    }
});