import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
// GridValueGetterParams
import { colors } from '../../styles/colors';
import { alpha } from "@mui/material";
import { useEffect, useState } from 'react';

const columns: GridColDef[] = [
    { field: 'Length', headerName: 'Length', flex: 1, headerAlign:'center', align: 'center', sortable:false},
    { field: 'Date', headerName: 'Date', flex: 1, headerAlign:'center', align:'center',sortable:false }
];

type props = {
    longhornID: number
}

export default function MeasurementTable(props: props) {

    const [measures, setMeasures] = useState<{ MeasurementID: number, Length: string, Date: string }[]>([]);

    useEffect(() => {
        async function getLonghorn(inputID: number) {
            if (props.longhornID !== 0) {
                let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${props.longhornID}/tipmeasures`, { method: 'get' })
                let data = await response.json();
                setMeasures(data)
            }
        };

        if (measures.length === 0) {
            getLonghorn(props.longhornID);
        }

    }, [measures.length, props.longhornID]);

    measures.forEach(e => e.Date = e.Date.split('T')[0])

    return (
        <div
            style={{
                height: 400,
                width: '100%',
                background: alpha(colors.palette.text.primary, .25),
                borderRadius: '16px',
                justifyContent: 'center'
            }}
        >
            <DataGrid
                getRowId={(measures) => measures.MeasurementID}
                rows={measures}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableSelectionOnClick
                sx={{
                    border: 'none',
                    color: colors.palette.text.secondary,
                    fontWeight: 'lighter',
                    ".MuiDataGrid-cell": {
                        borderBottom: '2px solid rgba(255, 255, 255, 0.25)'
                    },
                    ".MuiDataGrid-columnSeparator": {
                        display: 'none'
                    },
                    ".MuiDataGrid-columnHeaderTitleContainer": {
                        justifyContent: 'center',
                        fontWeight:'bold'
                    },
                    ".MuiDataGrid-footerContainer": {
                        borderTop: '2px solid rgba(255, 255, 255, 0.0)'
                    },
                    ".MuiDataGrid-columnHeaders": {
                        borderBottom: '2px solid rgba(255, 255, 255, 0.25)'
                    }
                }}
            />
        </div>
    );
}
