import AdminImageUploadUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";

export const AdminRanch: React.FC = () => {

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      let response = await fetch('https://hcrapi.azurewebsites.net/images', { method: 'get' })
      let data = await response.json();
      setImageList(await data);
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length]);

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  return (
    <>
      <Navbar />
      <Box sx={{
        backgroundColor: colors.palette.background.default
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              Upload Ranch Photos
            </Typography>
          </Box>
        </Box>
      </Box>
      <AdminImageUploadUI title="Upload photos of the ranch" id={0} type="ranch" imageList={imageList}></AdminImageUploadUI>
    </>
  )
}