import AdminImageUploadUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import AdminEditLonghornForm from "../../components/Admin/AdminEditLonghornForm"
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import { useParams } from "react-router-dom";
import useToken from "../../components/Admin/SendLogin";

export const AdminLonghornEdit: React.FC = () => {

    const params = useParams();
    var longhornID = 0;
    if (params.id !== undefined) {
        longhornID = parseInt(params.id);
    }

    const [longhorn, setLonghorn] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, RoleID: number, SexID: number, IsExternal: number, FatherLonghornID: number, MotherLonghornID: number, ReferenceNumber: string, DOB: string, Description: string }>({ LonghornID: 0, Name: "", RanchPrefix: "", RoleID: 0, SexID: 0, IsExternal: 0, FatherLonghornID: 0, MotherLonghornID: 0, ReferenceNumber: "", DOB: "", Description: "" })

    useEffect(() => {
        async function getLonghorn() {
            if (longhornID !== 0) {
                let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}`, { method: 'get' })
                let data = await response.json();
                setLonghorn(data[0]);
            }
        };

        if (longhorn.Name === "") {
            getLonghorn();
        }

    }, [longhorn.Name, longhornID]);

    const [imageList, setImageList] = useState<{ URL: string, Caption: string}[]>([]);

    
  useLayoutEffect(() => {
    async function getImages() {
      if (longhornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/images`, { method: 'get' })
        let data = await response.json();
        setImageList(await data);
      }
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length, longhornID]);

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} token={""} />
    }


    return (
        <>
            <Navbar />
            <AdminEditLonghornForm LonghornID={longhornID} imageList={imageList}></AdminEditLonghornForm>
            {longhornID > 0 && <AdminImageUploadUI title="Upload photo for this longhorn's slideshow" type="longhorn" id={longhornID} imageList={imageList}></AdminImageUploadUI>}
        </>
    )
}