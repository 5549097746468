import React from 'react';
import { Box, Typography } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

export const OSUConnection: React.FC = () => {

    return (
        <Box sx={{
            backgroundColor: colors.palette.primary.light,
            [theme.breakpoints.up('sm')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                paddingTop: '13vw',
                paddingBottom: '13vw'
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                paddingTop: '36vw',
                paddingBottom: '36vw'
            }
        }}
        >
            <Box component='img' draggable='false' sx={{
                objectFit: 'cover',
                // image size on screens below small
                [theme.breakpoints.down('sm')]: {
                    borderRadius: '36px',
                    width: '60vw'
                },
                [theme.breakpoints.up('md')]: {
                    borderRadius: '36px',
                    width: '30vw'
                },
                borderRadius: '64px',
                display: 'block',
                overflow: 'hidden',
                width: '100%',
                aspectRatio: '3/2',
                marginBottom: 8
            }}
                src="images/RCGame.jpeg"
                alt="Roger and Cindy Cagle at OSU game" />
            <Typography variant='h3' fontWeight={500}>
                OSU Connection
            </Typography>
            <Typography
                variant='h5'
                fontWeight={300}
                paddingTop={3}
                sx={{
                    color: colors.palette.text.primary,
                    opacity: '70%',
                    lineHeight: '2.2rem',
                    [theme.breakpoints.up('lg')]: {
                        width: '28vw'
                    },
                    [theme.breakpoints.between('md', 'lg')]: {
                        width: '32vw',
                    },
                    [theme.breakpoints.up('sm')]: {
                        width: '70vw'
                    },
                    [theme.breakpoints.down('sm')]: {
                        width: '70vw',
                        fontWeight: 400,
                        fontSize: '1.04rem',
                        lineHeight: '1.6rem'
                    },
                }}>
                After graduation from high school, in southeastern Oklahoma, Roger chose Oklahoma State University after checking out numerous universities in Texas, Oklahoma and Arkansas.  Stillwater was the perfect place for phase two of life beyond the hometown.
                After graduating high school in Dallas, Cindy continued her education at the University of Texas-Austin.  Both orange, one brighter!
                <br />
                <br />
                After we got together and pursued our professional careers, primarily abroad, we more or less lost touch with our respective alma maters.  However, in the early 2000s, the Oklahoma State University Spears Business School, found us in London and we reconnected.  Since that time, we both have deepened our relationship with Oklahoma State not only through connections whilst we were overseas, hosting both faculty and students and meeting other alumni abroad, but through attendance of events on campus in Stillwater.
                <br />
                <br />
                As a result, we partnered with the Spears School of Business to form the Center for Advanced Global Leadership and Engagement (CAGLE) to encourage students to travel abroad for learning and life experiences to better prepare them for success in an increasingly interconnected multinational world.  This is our way to hopefully positively influence the lives of a great number of students and to foster in them a greater understanding of the world on a personal level—to influence and be influenced.
            </Typography>
        </Box>
    )
}