import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';
import { Carousel } from '../Global/Carousel/Carousel';

export const LonghornsWrapper: React.FC = () => {

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);
  
  useEffect(() => {
    async function getImages() {
      let response = await fetch('https://hcrapi.azurewebsites.net/longhorns/0/images', {method: 'get'})
      let data = await response.json();
      setImageList(await data);
    };
    
    if (imageList.length === 0) {
      getImages();
    }

  }, [imageList.length]);

  return (
    <Box sx={{ backgroundColor: colors.palette.background.default }}>
      <Grid
        container spacing={6} justifyContent='space-between'
        pl={"12vw"} pr={"12vw"}
        sx={{
          display: 'flex',
          [theme.breakpoints.up('md')]: {
            paddingTop: '18vh',
            paddingBottom: '25vh'
          },
          [theme.breakpoints.down('md')]: {
            paddingTop: '0vh',
            paddingBottom: '12vh'
          },
          flexDirection: { xs: 'column', md: 'row' }
        }}
      >
        <Grid item md={6}
        >
          <Box pt={8}>
            <Typography
              variant='h1'
              sx={{ 
                color: colors.palette.text.secondary,
                [theme.breakpoints.up('lg')]: {
                  fontSize: '5rem'
                },
                [theme.breakpoints.up('md')]: {
                  fontSize: '4rem'
                },
                [theme.breakpoints.up('sm')]: {
                  fontSize: '3rem'
                },
                [theme.breakpoints.down('sm')]: {
                  fontSize: '2.6rem'
                },
              }}
            >
              Longhorns
            </Typography>

            <Typography
              variant='h5'
              fontWeight={300}
              paddingTop={3}
              sx={{ 
                color: colors.palette.text.secondary, 
                opacity: '70%', 
                lineHeight: '2.2rem',
                [theme.breakpoints.up('lg')]: {
                  width: '28vw'
                },
                [theme.breakpoints.between('md', 'lg')]: {
                  width: '32vw',
                },
                [theme.breakpoints.up('sm')]: {
                  width: '46vw'
                },
                [theme.breakpoints.down('sm')]: {
                  width: '54vw',
                  fontSize: '1.04rem',
                  lineHeight: '1.6rem'
                },
              }}
            >
              At High Cs Ranch, longhorns are raised, cared for, and bred to produce the healthiest specie possible.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          justifyContent="center"
          sx={{
            [theme.breakpoints.only('md')]: {
              width: '60vw',
              marginTop: '2vh'
            },
          }}
        >
          {imageList.length > 0 && <Carousel imageList={imageList} />}
        </Grid>
      </Grid>
    </Box >
  )
}