import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Typography, TextField, Select, FormControl, InputLabel, MenuItem, Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

// const useStyles = makeStyles(() => ({
//   root: {
//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//         borderColor: 'pink',
//       },
//       '&:hover fieldset': {
//         borderColor: 'yellow',
//       },
//       '&.Mui-focused fieldset': {
//         borderColor: 'green',
//       },
//     },
//   },
// }));

type props = {
  LonghornID: number,
  imageList: Array<{ URL: string, Caption: string }>
};

export default function AdminEditLonghornForm(props: props) {

  const [longhorn, setLonghorn] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, RoleID: number, SexID: number, IsExternal: number, FatherLonghornID: number, MotherLonghornID: number, ReferenceNumber: string, DOB: string, Description: string }>({ LonghornID: 0, Name: "", RanchPrefix: "", RoleID: 0, SexID: 0, IsExternal: 0, FatherLonghornID: 0, MotherLonghornID: 0, ReferenceNumber: "", DOB: "", Description: "" })

  useEffect(() => {
    async function getLonghorn() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/` + props.LonghornID, { method: 'get' })
      let data = await response.json();
      setLonghorn(data[0]);
    };

    if (props.LonghornID) {
      getLonghorn();
    }

  }, [props.LonghornID]);

  const name = longhorn.Name;

  const [fatherOptions, setFatherOptions] = useState<{ LonghornID: number, Name: string }[]>([])
  const [motherOptions, setMotherOptions] = useState<{ LonghornID: number, Name: string }[]>([])

  useEffect(() => {
    async function getLonghorn() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/bulls`, { method: 'get' })
      let data = await response.json();
      setFatherOptions(data);
    };

    if (fatherOptions.length === 0) {
      getLonghorn();
    }

  }, [fatherOptions.length]);

  useEffect(() => {
    async function getLonghorn() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/cows`, { method: 'get' })
      let data = await response.json();
      setMotherOptions(data);
    };

    if (motherOptions.length === 0) {
      getLonghorn();
    }

  }, [motherOptions.length]);

  const handleChange = (e: any) => {
    if (e) {
      setLonghorn({...longhorn, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    console.log(JSON.stringify({
      ...longhorn
    }))

    const username = sessionStorage.getItem('username')?.slice(1, -1);
    const password = sessionStorage.getItem('password')?.slice(1, -1);

    try {
      if (props.LonghornID !== 0) {
        fetch(`https://hcrapi.azurewebsites.net/longhorns/${props.LonghornID}/edit`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...longhorn,
              Username: username,
              Password: password
            })
        })
      }

      else {
        fetch(`https://hcrapi.azurewebsites.net/longhorns/add`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...longhorn,
              Username: username,
              Password: password
            })
        })
      }
      let message = document.getElementById("message")
      if (message) {
        message.innerHTML = "Operation completed successfully";
        setTimeout(function () {
          window.location.href = "/Admin/Longhorns"
        }, 1500)
      }
    }
    catch {
      console.log("Operation failed")
    }
  }

  return (
    <>
      <Box sx={{
        backgroundColor: colors.palette.background.default,
        color: colors.palette.text.secondary
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin/Longhorns' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              {name ? ('Edit ' + name + "'s Information") : ('Create Longhorn')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.up('sm')]: {
                paddingTop: '2vh',
                paddingBottom: '8vh'
              },
              [theme.breakpoints.down('sm')]: {
                paddingTop: '2vh',
                paddingBottom: '6vh'
              }
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            {longhorn.LonghornID === 0 &&
              <Typography
                variant='body2'
                width={'100%'}
                color={colors.palette.text.secondary}
                sx={{
                  opacity: '40%',
                  paddingTop: '4px',
                  textAlign: 'center',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'start'
                  }
                }}
              >
                Images can be uploaded after creating the longhorn and editing them from the previous screen
              </Typography>}
          </Box>
          <Box display={'flex'} justifyContent={'center'} mb={20}>
            <form className={'App'} onSubmit={handleSubmit} style={{ width: '20rem' }}>
              <Stack spacing={3}>
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  required
                  label="Name"
                  name="Name"
                  id="Name"
                  type="text"
                  inputProps={{ maxLength: 50 }}
                  fullWidth
                  value={longhorn.Name}
                  placeholder="Name"
                  onChange={handleChange}
                  key="Name"
                />
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  label="Ranch Prefix"
                  name="RanchPrefix"
                  type="text"
                  inputProps={{ maxLength: 20 }}
                  fullWidth
                  value={longhorn.RanchPrefix}
                  placeholder="Ranch Prefix"
                  onChange={handleChange}
                  key="RanchPrefix"
                />

                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  label="Reference Number"
                  name="ReferenceNumber"
                  type="text"
                  inputProps={{ maxLength: 12 }}
                  fullWidth
                  value={longhorn.ReferenceNumber}
                  placeholder="Reference Number"
                  onChange={handleChange}
                  key="ReferenceNumber"
                />
                <FormControl fullWidth sx={{
                  '& .MuiSvgIcon-root': {
                    color: colors.palette.text.secondary
                  },
                  '& .MuiFormLabel-root': {
                    color: colors.palette.text.secondary + '!important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.palette.primary.light
                    },
                    '&:hover fieldset': {
                      borderColor: colors.palette.secondary.light
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.palette.primary.main
                    },
                  },
                }}>
                  <InputLabel id="RoleIDLabel"
                    style={{
                      color: colors.palette.text.secondary
                    }}
                  >
                    Role
                  </InputLabel>
                  <Select
                    required
                    id="RoleIDLabel"
                    name="RoleID"
                    label="Role"
                    onChange={handleChange}
                    key="RoleID"
                    value={longhorn.RoleID}
                  >
                    <MenuItem value="1">Bull</MenuItem>
                    <MenuItem value="2">Steer</MenuItem>
                    <MenuItem value="3">Cow</MenuItem>
                    <MenuItem value="4">Heifer</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{
                  '& .MuiSvgIcon-root': {
                    color: colors.palette.text.secondary
                  },
                  '& .MuiFormLabel-root': {
                    color: colors.palette.text.secondary + '!important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.palette.primary.light
                    },
                    '&:hover fieldset': {
                      borderColor: colors.palette.secondary.light
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.palette.primary.main
                    },
                  },
                }}>
                  <InputLabel id="SexIDLabel"
                    style={{
                      color: colors.palette.text.secondary
                    }}
                  >
                    Sex
                  </InputLabel>
                  <Select
                    required
                    id="SexIDLabel"
                    label="Sex"
                    name="SexID"
                    key="SexID"
                    value={longhorn.SexID}
                    onChange={handleChange}
                  >
                    <MenuItem value="0">Male</MenuItem>
                    <MenuItem value="1">Female</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{
                  '& .MuiSvgIcon-root': {
                    color: colors.palette.text.secondary
                  },
                  '& .MuiFormLabel-root': {
                    color: colors.palette.text.secondary + '!important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.palette.primary.light
                    },
                    '&:hover fieldset': {
                      borderColor: colors.palette.secondary.light
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.palette.primary.main
                    },
                  },
                }}
                >
                  <InputLabel id="SireID"
                    style={{
                      color: colors.palette.text.secondary
                    }}
                  >
                    Select Longhorn Sire
                  </InputLabel>
                  <Select
                    required
                    id="SireID"
                    label="Select Longhorn Sire"
                    name="FatherLonghornID"
                    onChange={handleChange}
                    key="FatherLonghornID"
                    value={longhorn.FatherLonghornID}
                  >
                    <MenuItem value="0">Unknown/Intentionally Left Blank</MenuItem>
                    {fatherOptions && fatherOptions.map(({ LonghornID, Name }: any) => (
                      <MenuItem value={LonghornID} key={LonghornID} selected={LonghornID === longhorn.FatherLonghornID}>
                        {Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{
                  '& .MuiSvgIcon-root': {
                    color: colors.palette.text.secondary
                  },
                  '& .MuiFormLabel-root': {
                    color: colors.palette.text.secondary + '!important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.palette.primary.light
                    },
                    '&:hover fieldset': {
                      borderColor: colors.palette.secondary.light
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.palette.primary.main
                    },
                  },
                }}
                >
                  <InputLabel id="DamID">Select Longhorn Dam</InputLabel>
                  <Select
                    required
                    id="DamID"
                    label="Select Longhorn Dam"
                    name="MotherLonghornID"
                    onChange={handleChange}
                    key="MotherLonghornID"
                    value={longhorn.MotherLonghornID}
                  >
                    <MenuItem value="0">Unknown/Intentionally Left Blank</MenuItem>
                    {motherOptions && motherOptions.map(({ LonghornID, Name }: any) => (
                      <MenuItem value={LonghornID} key={LonghornID} selected={LonghornID === longhorn.MotherLonghornID}>
                        {Name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField fullWidth
                  id="date"
                  label="Date of Birth"
                  type="date"
                  name="DOB"
                  value={longhorn.DOB && longhorn.DOB.split('T')[0]}
                  onChange={handleChange}
                  key="DOB"
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  inputProps={{ style: { color: colors.palette.text.secondary } }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <FormControl fullWidth sx={{
                  '& .MuiSvgIcon-root': {
                    color: colors.palette.text.secondary
                  },
                  '& .MuiFormLabel-root': {
                    color: colors.palette.text.secondary + '!important'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: colors.palette.primary.light
                    },
                    '&:hover fieldset': {
                      borderColor: colors.palette.secondary.light
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.palette.primary.main
                    },
                  },
                }}>
                  <InputLabel id="IsExternal">Is external?</InputLabel>
                  <Select
                    id="IsExternal"
                    name="IsExternal"
                    label="Is external?"
                    onChange={handleChange}
                    key="IsExternal"
                    value={longhorn.IsExternal}
                  >
                    <MenuItem value="0">No</MenuItem>
                    <MenuItem value="1">Yes</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  fullWidth
                  label="Description"
                  name="Description"
                  multiline
                  rows={4}
                  variant="outlined"
                  key="Description"
                  sx={{
                    maxLength: 1000,
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  InputProps={{
                    style: { backgroundColor: 'none' }
                  }}
                  inputProps={{ style: { color: colors.palette.text.secondary } }}
                  value={longhorn.Description}
                  onChange={handleChange}
                />
                <Box display={'flex'} justifyContent={'end'} pt={2}>
                  <Button sx={{ color: colors.palette.primary.light }} type="submit">{props.LonghornID === 0 ? 'Create' : 'Save Changes'}</Button>
                  <Box>
                    <label id="message"></label>
                  </Box>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  )
}