import { AppBar, SvgIcon, Toolbar, Typography } from '@mui/material';
import { Cross } from 'hamburger-react';
import { StyledIconButton } from './styled-components/IconButton.styled';
import { Box } from '@mui/material';
import { StyledButton } from './styled-components/Button.styled';
import StyledDrawer from './styled-components/Drawer.styled';
import { useState } from 'react';
import { colors } from '../../../styles/colors';
import { theme } from '../../../styles/theme';

export default function Navbar() {

    function handleBoth() {
        handleClick();
        handleLogoDisappear();
    }

    function handleClick() {
        isShown(!show)
    }
    function handleLogoDisappear() {
        logoIsShown(!showLogo)
    }

    const [show, isShown] = useState(false);
    const [showLogo, logoIsShown] = useState(true);

    return (
        <>
            <AppBar sx={{ backgroundColor: colors.palette.background.default }}>
                <Toolbar sx={{
                    width: '74vw',
                    [theme.breakpoints.down('sm')]: {
                        paddingRight: 0
                    }
                }}>
                    {
                        showLogo ?
                            <Box style={{ flexGrow: 1 }}>
                                <a href='/'
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                >
                                    <SvgIcon sx={{color: colors.palette.primary.main, preserveAspectRatio: 'xMinYMin', transform: 'scale(4)'}} width="120px" viewBox="0 0 120.27 70.55">
                                        <path d="M82.87,46.44c6.27-2.91,14.37-3.31,20.79-2.61l4.67,.5s-11.99-8.38-28.45-3.88c-4.77,1.3-14.35,6.97-22.21,9.62-8.47,2.86-14.88,3.04-20.94,2.86-14.88-.45-26.95-9.85-26.95-21.79S21.99,9.35,37,9.35c6.98,0,13.61,2.12,18.69,5.98h10.81S57.55,2.4,38.05,1.95c-.35,0-.7-.02-1.05-.02-4.01,0-7.86,.58-11.44,1.64C12.06,7.56,2.36,18.41,2.36,31.15s9.71,23.58,23.2,27.58c3.58,1.06,7.91,1.6,11.44,1.64,24.15,.29,36.02-9.35,45.87-13.92Z" />
                                        <path d="M83.02,53.14c-4.79,2.19-9.7,5.15-18.29,8.36-6.88,2.57-19.18,2.65-19.18,2.65,0,0,6.37,3.84,22.26,2.45,8.67-.76,18.38-6.18,25.43-10.09,6.05-3.35,14.29-3.66,20.72-3.12l4.68,.39s-15.61-9.81-35.63-.64Z" />
                                        <path d="M60.49,34.46c-4.84,2.08-9.82,4.91-18.48,7.91-6.94,2.4-19.24,2.18-19.24,2.18,0,0,6.28,4,22.2,2.98,4.46-.28,9.21-1.79,13.76-3.69,4.26-1.78,8.34-3.91,11.79-5.72,.04-.02,.08-.04,.12-.06,3.25-1.7,7.09-2.52,10.95-2.82,3.41-.26,6.83-.12,9.84,.21l4.67,.51s-5.4-3.58-14.02-4.68c-5.92-.76-13.34-.35-21.58,3.19Z" />
                                    </SvgIcon>
                                </a>
                            </Box> : null
                    }
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        [theme.breakpoints.up('sm')]: {
                            gap: '2vw'
                        },
                        [theme.breakpoints.down('sm')]: {
                            gap: '4vw'
                        }
                    }}
                    >
                        {/* Registered Longhorns Link */}
                        <a href='/Longhorns' style={{ textDecoration: 'none' }}>
                            <StyledButton
                                variant='navButtons'
                                disableTouchRipple
                            >
                                <Typography variant='subtitle2'>
                                    Registered Longhorns
                                </Typography>
                            </StyledButton>
                        </a>

                        {/* Exotics Link */}
                        <a href='/Exotics' style={{ textDecoration: 'none' }}>
                            <StyledButton
                                variant='navButtons'
                                disableTouchRipple
                            >
                                <Typography variant='subtitle2'>
                                    Exotics
                                </Typography>
                            </StyledButton>
                        </a>

                        {/* Other Denizens Link */}
                        <a href='/Denizens' style={{ textDecoration: 'none' }}>
                            <StyledButton
                                variant='navButtons'
                                disableTouchRipple
                            >
                                <Typography variant='subtitle2'>
                                    Other Denizens
                                </Typography>
                            </StyledButton>
                        </a>

                        {/* Affilitations Link */}
                        <a href='/Affiliations' style={{ textDecoration: 'none' }}>
                            <StyledButton
                                variant='navButtons'
                                disableTouchRipple
                            >
                                <Typography variant='subtitle2'>
                                    Affiliations
                                </Typography>
                            </StyledButton>
                        </a>

                        {/* About Link */}
                        <a href='/About' style={{ textDecoration: 'none' }}>
                            <StyledButton
                                variant='navButtons'
                                disableTouchRipple
                            >
                                <Typography variant='subtitle2'>
                                    About
                                </Typography>
                            </StyledButton>
                        </a>

                        {/* Navbar button */}
                        <StyledIconButton disableTouchRipple onClick={handleBoth} sx={{justifyContent: 'end'}}>
                            <Cross color={colors.palette.primary.main} />
                        </StyledIconButton>

                    </Box>
                </Toolbar>
            </AppBar>
            
            {
                show ? <StyledDrawer /> : null
            }

        </>
    );
}