import AdminImageUI from "../../components/Admin/AdminImageUI"
import { AdminDenizenSection } from "../../components/Admin/AdminDenizen";
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";

export const AdminDenizen: React.FC = () => {

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      let response = await fetch('https://hcrapi.azurewebsites.net/denizens/images', { method: 'get' })
      let data = await response.json();
      setImageList(await data);
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length]);


  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  return (
    <>
      <Navbar />
      <AdminDenizenSection />
      <AdminImageUI title="Upload photos of ranch denizens" id={0} type="denizen" imageList={imageList} />
    </>
  )
}