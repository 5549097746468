import { HomeWrapper } from "../components/Home/HomeWrapper";
import Navbar from "../components/Global/Navbar/Navbar";
import { AboutSection } from "../components/Home/HomeAboutSection";
import { HomeLonghornsSection } from "../components/Home/HomeLonghornsSection";
import { HomeExoticsSection } from "../components/Home/HomeExoticsSection";
import { Footer } from "../components/Global/Footer/Footer";


export const Home: React.FC = () => {
    return (
        <>
            <Navbar />
            <HomeWrapper />
            <AboutSection />
            <HomeLonghornsSection />
            <HomeExoticsSection />
            <Footer />
        </>
    )
}