import Navbar from "../components/Global/Navbar/Navbar";
import { IndividualExoticSection } from "../components/IndividualExotic/IndividualExoticSection";

export const IndividualExotic: React.FC = () => {
    return (
        <>
            <Navbar />
            <IndividualExoticSection />
        </>
    )
}