import { useEffect, useState } from "react";
import { Footer } from "../components/Global/Footer/Footer"
import Navbar from "../components/Global/Navbar/Navbar"
import { OtherDenizensWrapper } from "../components/OtherDenizens/OtherDenizensWrapper"

export const OtherDenizens: React.FC = () => {
    
    const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);
  
    useEffect(() => {
      async function getImages() {
        let response = await fetch('https://hcrapi.azurewebsites.net/denizens/images', {method: 'get'})
        let data = await response.json();
        setImageList(await data);
      };
      
      if (imageList.length === 0) {
        getImages();
      }
  
    }, [imageList.length]);

    return (
        <>
            <Navbar />
            <OtherDenizensWrapper imageList={imageList}></OtherDenizensWrapper>
            <Footer />
        </>
    )
}