import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Link } from '@mui/material';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

export const AffiliationsWrapper: React.FC = () => {

  const [affiliations, setAffiliations] = useState<({ URL: string, Name: string })[]>([])

  useEffect(() => {
    async function getAffs() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/affiliations`, { method: 'get' })
      let data = await response.json();
      setAffiliations(data);
    };

    if (affiliations.length === 0) {
      getAffs();
    }
  }, [affiliations.length]);

  return (
    <Box sx={{ backgroundColor: colors.palette.background.default }}>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Typography
          textAlign='center'
          variant='h2'
          pt={10}
          sx={{
            color: colors.palette.text.secondary
          }}
        >
          Affiliations
        </Typography>
      </Box>

      <Box
        alignItems={'center'}
        pl={"12vw"} pr={"12vw"} pt={"8vh"} pb={"24vh"}
      >
        <Box alignItems={'center'} display={'flex'} flexDirection={'column'} sx={{gap: 3}}>
          {affiliations.map((affiliation, index) => (
            <Button
              variant='text'
              endIcon={<ArrowOutwardIcon sx={{
                  [theme.breakpoints.down('md')]: {
                      display: 'none'
                  },
                color: colors.palette.text.secondary,
                opacity: '60%'
              }} />}
            >
              <Link href={affiliation.URL} variant='mobileNavMenu' sx={{color: 'white !important', opacity: '60%'}}>
                {affiliation.Name}
              </Link>
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}