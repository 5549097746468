import React, { useEffect, useRef, useState } from 'react';
import { Grid, Box, Typography, Divider, Chip, Link, Fab } from '@mui/material';
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';
import MeasurementTable from './MeasurementTable';
import { useParams } from 'react-router-dom';
import { Carousel } from '../Global/Carousel/Carousel';

export const IndividualLonghornSection: React.FC = () => {

  const params = useParams();
  var mainLonghornID = 0;
  if (params.id !== undefined) {
    mainLonghornID = parseInt(params.id);
  }

  const [longhorn, setLonghorn] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, RoleID: number, SexID: number, IsExternal: number, FatherLonghornID: number, MotherLonghornID: number, ReferenceNumber: string, DOB: string, Description: string }>({ LonghornID: 0, Name: "", RanchPrefix: "", RoleID: 0, SexID: 0, IsExternal: 0, FatherLonghornID: 0, MotherLonghornID: 0, ReferenceNumber: "", DOB: "", Description: "" })

  useEffect(() => {
    async function getLonghorn() {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${mainLonghornID}`, { method: 'get' })
        let data = await response.json();
        setLonghorn(data[0]);
      }
    };

    if (longhorn.Name === "") {
      getLonghorn();
    }

  }, [longhorn.Name, mainLonghornID]);

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${mainLonghornID}/images`, { method: 'get' })
        let data = await response.json();
        setImageList(data);
      }
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length, mainLonghornID]);

  var [parents, setParents] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, SexID: Number, FatherLonghornID: number, MotherLonghornID: number }[]>([]);

  var [damParents, setDamParents] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, SexID: Number, FatherLonghornID: number, MotherLonghornID: number }[]>([]);

  var [sireParents, setSireParents] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, SexID: Number, FatherLonghornID: number, MotherLonghornID: number }[]>([]);

  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    async function getParents() {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${mainLonghornID}/parents`, { method: 'get' })
        var data = await response.json();
        setParents(data)
      }
    };

    if (parents.length === 0 && calledOnce.current === false) {
      getParents();
      calledOnce.current = true;
    }
  }, [parents.length, mainLonghornID, parents, calledOnce]);

  useEffect(() => {
    let counter = 0;

    async function getParents(longhornID: number) {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/parents`, { method: 'get' })
        var data = await response.json();
        setSireParents(data)
        counter = 1
      }
    };

    if (parents.length > 0 && sireParents.length === 0 && counter === 0) {
      parents.forEach(a => {
        if (a.SexID === 0) {
          getParents(a.LonghornID);
        }
      })
    }
  }, [sireParents.length, parents.length, mainLonghornID, parents]);

  useEffect(() => {
    async function getParents(longhornID: number) {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/parents`, { method: 'get' })
        var data = await response.json();
        setDamParents(data)
      }
    };

    if (parents.length > 0 && damParents.length === 0) {
      parents.forEach(a => {
        if (a.SexID === 1) {
          getParents(a.LonghornID);
        }
      })
    }
  }, [damParents.length, parents.length, mainLonghornID, parents]);

  const [offspring, setOffspring] = useState<{ LonghornID: number, Name: string, RanchPrefix: string, SexID: Number }[]>([]);

  useEffect(() => {
    async function getLonghorn() {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${mainLonghornID}/children`, { method: 'get' })
        let data = await response.json();
        setOffspring(data);
      }
    };

    if (offspring.length === 0) {
      getLonghorn();
    }

  }, [offspring.length, mainLonghornID]);

  const [measures, setMeasures] = useState<{ MeasurementID: number, Length: string, Date: string }[]>([]);

  useEffect(() => {
    async function getLonghorn(inputID: number) {
      if (mainLonghornID !== 0) {
        let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/${mainLonghornID}/tipmeasures`, { method: 'get' })
        let data = await response.json();
        setMeasures(data)
      }
    };

    if (measures.length === 0) {
      getLonghorn(mainLonghornID);
    }

  }, [measures.length, mainLonghornID]);

  return (
    <>
      <div style={{
        position: 'fixed',
        bottom: '50px',
        right: '50px',
        zIndex: '4'
      }}
      >
        <a href="mailto:info@highcsranch.com" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Fab>
            Email
          </Fab>
        </a>
      </div>
      <Box sx={{ backgroundColor: colors.palette.background.default }}>
        <Grid
          container spacing={6} justifyContent='space-between'
          pl={"12vw"} pr={"12vw"}
          sx={{
            display: 'flex',
            [theme.breakpoints.up('md')]: {
              paddingTop: '8vh',
              paddingBottom: '25vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingTop: '0vh',
              paddingBottom: '15vh'
            },
            flexDirection: { xs: 'column', md: 'row' }
          }}
        >
          <Grid item md={6}
          >
            <Box pt={4}>
              <Typography
                variant='h2'
                sx={{
                  color: colors.palette.text.secondary,
                  [theme.breakpoints.up('md')]: {
                    fontSize: '3.6rem'
                  },
                  // [theme.breakpoints.between('md', 'lg')]: {
                  //   fontSize: '4rem'
                  // },
                  [theme.breakpoints.between('sm', 'md')]: {
                    fontSize: '2.8rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '2.2rem'
                  },
                }}
              >
                {longhorn.RanchPrefix} {longhorn.Name}
              </Typography>

              <Typography
                variant='subtitle1'
                paddingTop={1}
                paddingBottom={4}
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                letterSpacing='1px'
                fontWeight={550}
                sx={{
                  [theme.breakpoints.up('lg')]: {
                    fontSize: '1.1rem'
                  },
                  [theme.breakpoints.between('sm', 'lg')]: {
                    fontSize: '.9rem'
                  },
                  [theme.breakpoints.down('sm')]: {
                    fontSize: '.6rem'
                  },
                  opacity: '60%'
                }}
              >
                Longhorn
              </Typography>
            </Box>
            <Box
              sx={{
                // image size on screens below small
                [theme.breakpoints.down('sm')]: {
                  borderRadius: '36px'
                },
                borderRadius: '64px',
                display: 'block',
                overflow: 'hidden'
              }}
            >
              {imageList.length > 1 ?
                <Carousel imageList={imageList}></Carousel>
                : imageList.length !== 0 &&
                <img src={imageList[0].URL}
                  alt={imageList[0].Caption}
                  draggable='false'
                  width='100%'
                  height='100%'
                  style={{
                    objectFit: 'cover'
                  }}>

                </img>
              }
            </Box>
          </Grid>

          {/* Right side of page */}
          <Grid
            item
            md={5}
            justifyContent="start"
            sx={{

              [theme.breakpoints.up('md')]: {
                width: '60vw',
                marginTop: '18vh'
              },
            }}
          >
            {longhorn.ReferenceNumber !== null && longhorn.ReferenceNumber !== "" && <Box sx={{
              [theme.breakpoints.up('md')]: {
                // marginTop: 20
              }
            }}>
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                Reference Number
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '.2rem',
              }}
              />
              <Typography
                variant='body1'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                {longhorn.ReferenceNumber}
              </Typography>
            </Box>
            }
            <Box sx={{
              marginTop: 4
            }}
            >
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                Gender
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '.2rem',
              }}
              />
              <Typography
                variant='body1'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                {longhorn.SexID === 0 ? <span>Male</span> : <span>Female</span>}
              </Typography>
            </Box>

            {longhorn.DOB !== null && <Box sx={{
              marginTop: 4
            }}
            >
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                DOB
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '.2rem',
              }}
              />
              <Typography
                variant='body1'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                {longhorn.DOB.split('T')[0]}
              </Typography>
            </Box>}

            {parents.length !== 0 && <Box sx={{
              marginTop: 4
            }}
            >
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                Pedigree
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '1.5rem',
              }}
              />

              <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <Typography
                  variant='body1'
                  mt={2}
                  textAlign={'center'}
                  sx={{
                    opacity: '50%',
                    fontWeight: '500 !important',
                    letterSpacing: .5,
                    fontSize: '1rem',
                    color: colors.palette.text.secondary
                  }}
                >
                  Parents
                </Typography>
                {parents.map(({ LonghornID, Name, SexID, RanchPrefix, FatherLonghornID, MotherLonghornID }, index) => (
                  <div key={index}>
                    <Link href={`/Longhorns/${LonghornID}`}>

                      <Chip label={(
                        <section style={{
                          display: 'flex'
                        }}>
                          <Box>
                            <Typography variant='chipLabel' sx={{ pr: .5 }}>
                              {SexID === 0 ? 'Sire' : 'Dam'} |
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant='chipLabel' sx={{ opacity: '100%' }}>
                              {RanchPrefix} {Name}
                            </Typography>
                          </Box>
                        </section>
                      )}
                        variant='outlined'
                        sx={{
                          background: '#ffffff10',
                          color: '#ffffffd5'
                        }}
                      />
                    </Link>
                  </div>

                ))}
              </Box>
              {sireParents.length > 0 && <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <Typography
                  variant='body1'
                  textAlign={'center'}
                  mt={4}
                  sx={{
                    opacity: '50%',
                    fontWeight: '500 !important',
                    letterSpacing: .5,
                    fontSize: '1rem',
                    color: colors.palette.text.secondary
                  }}
                >
                  Sire's Parents
                </Typography>
                {sireParents.map(({ LonghornID, Name, SexID, RanchPrefix, FatherLonghornID, MotherLonghornID }, index) => (
                  <div key={index}>
                    <Link href={`/Longhorns/${LonghornID}`}>

                      <Chip label={(
                        <section style={{
                          display: 'flex'
                        }}>
                          <Box>
                            <Typography variant='chipLabel' sx={{ pr: .5 }}>
                              {SexID === 0 ? 'Sire' : 'Dam'} |
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant='chipLabel' sx={{ opacity: '100%' }}>
                              {RanchPrefix} {Name}
                            </Typography>
                          </Box>
                        </section>
                      )}
                        variant='outlined'
                        sx={{
                          background: '#ffffff10',
                          color: '#ffffffd5'
                        }}
                      />
                    </Link>
                  </div>

                ))}
              </Box>}

              {damParents.length > 0 && <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <Typography
                  variant='body1'
                  textAlign={'center'}
                  mt={4}
                  sx={{
                    opacity: '50%',
                    fontWeight: '500 !important',
                    letterSpacing: .5,
                    fontSize: '1rem',
                    color: colors.palette.text.secondary
                  }}
                >
                  Dam's Parents
                </Typography>
                {damParents.map(({ LonghornID, Name, SexID, RanchPrefix, FatherLonghornID, MotherLonghornID }, index) => (
                  <div key={index}>
                    <Link href={`/Longhorns/${LonghornID}`}>

                      <Chip label={(
                        <section style={{
                          display: 'flex'
                        }}>
                          <Box>
                            <Typography variant='chipLabel' sx={{ pr: .5 }}>
                              {SexID === 0 ? 'Sire' : 'Dam'} |
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant='chipLabel' sx={{ opacity: '100%' }}>
                              {RanchPrefix} {Name}
                            </Typography>
                          </Box>
                        </section>
                      )}
                        variant='outlined'
                        sx={{
                          background: '#ffffff10',
                          color: '#ffffffd5'
                        }}
                      />
                    </Link>
                  </div>

                ))}
              </Box>}

            </Box>
            }

            {/* Offspring Section */}
            {offspring.length !== 0 && <Box sx={{
              marginTop: 4
            }}
            >
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                Offspring
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '1.5rem',
              }}
              />

              <Box sx={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                {/* Chip 1 */}
                {offspring.map(({ LonghornID, Name, RanchPrefix, SexID }, index) => (
                  <Link href={`/Longhorns/${LonghornID}`}>
                    <Chip label={(
                      <section style={{
                        display: 'flex'
                      }}>
                        <Box>
                          <Typography variant='chipLabel' sx={{ pr: .5 }}>
                            {longhorn.SexID === 0 ? <span>Male</span> : <span>Female</span>} |
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant='chipLabel' sx={{ opacity: '100%' }}>
                            {RanchPrefix} {Name}
                          </Typography>
                        </Box>
                      </section>
                    )}
                      variant='outlined'
                      sx={{
                        background: '#ffffff10',
                        color: '#ffffffd5'
                      }}
                    />
                  </Link>
                ))}
              </Box>
            </Box>}

            <Box>
              <Typography
                variant='subtitle1'
                fontWeight={300}
                paddingTop={3}
                sx={{
                  color: colors.palette.text.secondary,
                  opacity: '70%',
                  lineHeight: '2.2rem',
                  [theme.breakpoints.up('lg')]: {
                    width: '20vw'
                  },
                  [theme.breakpoints.between('sm', 'lg')]: {
                    width: '32vw',
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '54vw',
                    fontSize: '1.04rem',
                    lineHeight: '1.6rem'
                  },
                }}
              >
                {longhorn.Description}
              </Typography>
            </Box>

            {measures.length > 0 && <Box sx={{
              marginTop: 4
            }}
            >
              <Typography
                variant='subtitle2alt'
                textTransform='uppercase'
                color={colors.palette.text.secondary}
                fontWeight={550}
              >
                Measurement History
              </Typography>
              <Divider sx={{
                marginTop: '.6rem',
                marginBottom: '1.5rem',
              }}
              />

              {/* Measurement History Table */}
              <MeasurementTable longhornID={mainLonghornID} />
            </Box>}
          </Grid>
        </Grid>
      </Box >
    </>
  )
}