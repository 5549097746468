import React, { useEffect, useState } from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Divider, Button } from '@mui/material';
import { colors } from '../../styles/colors';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { theme } from '../../styles/theme';

export const LonghornsSection: React.FC = () => {

    const [imageList, setImageList] = useState<{ EntityID: number, Description: string, URL: string, Caption: string }[]>([]);
    const [cowRole, setCowRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })
    const [bullRole, setBullRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })
    const [steerRole, setSteerRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })
    const [heiferRole, setHeiferRole] = useState<{ RoleID: number, Name: string, Description: string }>({ RoleID: 0, Name: '', Description: '' })

    useEffect(() => {
        async function getImages() {
            let response = await fetch('https://hcrapi.azurewebsites.net/longhorns/roles/images', { method: 'get' })
            let data = await response.json();
            setImageList(data);
        };

        if (imageList.length === 0) {
            getImages();
        }

    }, [imageList.length]);

    useEffect(() => {
        async function getLonghorn() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/1`, { method: 'get' })
            let data = await response.json();
            setBullRole(data[0]);
        };

        if (bullRole.Name === '') {
            getLonghorn();
        }

    }, [bullRole.Name]);

    useEffect(() => {
        async function getLonghorn() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/2`, { method: 'get' })
            let data = await response.json();
            setSteerRole(data[0]);
        };

        if (steerRole.Name === '') {
            getLonghorn();
        }

    }, [steerRole.Name]);

    useEffect(() => {
        async function getLonghorn() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/3`, { method: 'get' })
            let data = await response.json();
            setCowRole(data[0]);
        };

        if (cowRole.Name === '') {
            getLonghorn();
        }

    }, [cowRole.Name]);

    useEffect(() => {
        async function getLonghorn() {
            let response = await fetch(`https://hcrapi.azurewebsites.net/longhorns/roles/4`, { method: 'get' })
            let data = await response.json();
            setHeiferRole(data[0]);
        };

        if (heiferRole.Name === '') {
            getLonghorn();
        }

    }, [heiferRole.Name]);

    const bullImage = imageList.find(o => o.EntityID === 1)
    const steerImage = imageList.find(o => o.EntityID === 2)
    const cowImage = imageList.find(o => o.EntityID === 3)
    const heiferImage = imageList.find(o => o.EntityID === 4)

    return (
        <Box pl={"12vw"} pr={"12vw"} pt={"4vh"} pb={"15vh"}
            sx={{
                backgroundColor: colors.palette.background.default
            }}
        >
            <Box
                display='flex'
                justifyContent='center'
                flexDirection='column'
                pt='4vw'
            >
                <Box>
                    <Typography
                        variant='h2'
                        paddingBottom={'2rem'}
                        sx={{
                            color: colors.palette.text.secondary,
                            textAlign: 'center'
                        }}
                    >
                        Our Stock
                    </Typography>
                    <Divider sx={{
                        width: '10vw',
                        backgroundColor: colors.palette.primary.light,
                        margin: 'auto',
                        marginBottom: '4rem'
                    }} />
                </Box>

                {/* CARD 1 STARTS HERE */}

                <Box sx={{
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <a href="/Longhorns/List/cows" style={{
                        textDecoration: 'none',
                        borderColor: 'none'
                    }}>
                        <Card
                            variant='outlined'
                            sx={{
                                gap: 2,
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    width: '76vw'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '76vw'
                                },
                            }}
                        >
                            <Box sx={{
                                [theme.breakpoints.up('md')]: {
                                    padding: '3rem'
                                },
                                [theme.breakpoints.down('md')]: {
                                    padding: '2rem'
                                },
                            }}>
                                <Typography
                                    variant='h4'
                                    component='div'
                                >
                                    Cows
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    marginTop='.8rem'
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: '24vw',
                                            fontSize: '1.6rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '48vw',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: 'auto'
                                        },
                                    }}
                                >
                                    {cowRole.Description && cowRole.Description.slice(0,200) + "..."}
                                </Typography>
                                <Box
                                    mt={'1rem'}
                                    ml={'-.4rem'}
                                    sx={{ opacity: '60%' }}
                                >
                                    <Button
                                        variant='text'
                                        endIcon={<CallMadeIcon sx={{
                                            color: colors.palette.text.primary
                                        }} />}
                                    >
                                        <Typography variant='cardButton'>
                                            See cows
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.between('md', 'xl')]: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end'
                                    },
                                }}
                            >
                                {cowImage?.URL !== undefined && <CardContent>
                                    <CardMedia
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                height: '340px',
                                                width: '35vw'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                height: '30vh',
                                                width: '100%'
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                height: '210px'
                                            }
                                        }}
                                        component='img'
                                        width='100%'
                                        image={cowImage?.URL}
                                        alt={cowImage?.Caption}
                                    />
                                </CardContent>}

                            </Box>
                        </Card>
                    </a>
                </Box>

                <Box sx={{
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <a href="/Longhorns/List/heifers" style={{
                        textDecoration: 'none',
                        borderColor: 'none'
                    }}>
                        <Card
                            variant='outlined'
                            sx={{
                                gap: 2,
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    width: '76vw'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '76vw'
                                },
                            }}
                        >
                            <Box sx={{
                                [theme.breakpoints.up('md')]: {
                                    padding: '3rem'
                                },
                                [theme.breakpoints.down('md')]: {
                                    padding: '2rem'
                                },
                            }}>
                                <Typography
                                    variant='h4'
                                    component='div'
                                >
                                    Heifers
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    marginTop='.8rem'
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: '24vw',
                                            fontSize: '1.6rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '48vw',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: 'auto'
                                        },
                                    }}
                                >
                                    {heiferRole.Description && heiferRole.Description.slice(0,200) + "..."}
                                </Typography>
                                <Box
                                    mt={'1rem'}
                                    ml={'-.4rem'}
                                    sx={{ opacity: '60%' }}
                                >
                                    <Button
                                        variant='text'
                                        endIcon={<CallMadeIcon sx={{
                                            color: colors.palette.text.primary
                                        }} />}
                                    >
                                        <Typography variant='cardButton'>
                                            See heifers
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.between('md', 'xl')]: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end'
                                    },
                                }}
                            >
                                {heiferImage?.URL !== undefined &&
                                    <CardContent>
                                        <CardMedia
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    height: '340px',
                                                    width: '35vw'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    height: '30vh',
                                                    width: '100%'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    height: '210px'
                                                }
                                            }}
                                            component='img'
                                            width='100%'
                                            image={heiferImage?.URL}
                                            alt={heiferImage?.Caption}
                                        />
                                    </CardContent>}
                            </Box>
                        </Card>
                    </a>
                </Box>

                {/* CARD 2 STARTS HERE */}

                <Box sx={{
                    marginTop: '1.4rem',
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <a href="/Longhorns/List/bulls" style={{
                        textDecoration: 'none',
                        borderColor: 'none'
                    }}>
                        <Card
                            variant='outlined'
                            sx={{
                                gap: 2,
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    width: '76vw'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '76vw'
                                },
                            }}
                        >
                            <Box sx={{
                                [theme.breakpoints.up('md')]: {
                                    padding: '3rem'
                                },
                                [theme.breakpoints.down('md')]: {
                                    padding: '2rem'
                                },
                            }}>
                                <Typography
                                    variant='h4'
                                    component='div'
                                >
                                    Bulls
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    marginTop='.8rem'
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: '24vw',
                                            fontSize: '1.6rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '48vw',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: 'auto'
                                        },
                                    }}
                                >
                                    {bullRole.Description && bullRole.Description.slice(0,200) + "..."}
                                </Typography>
                                <Box
                                    mt={'1rem'}
                                    ml={'-.4rem'}
                                    sx={{ opacity: '60%' }}
                                >
                                    <Button
                                        variant='text'
                                        endIcon={<CallMadeIcon sx={{
                                            color: colors.palette.text.primary
                                        }} />}
                                    >
                                        <Typography variant='cardButton'>
                                            See bulls
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.between('md', 'xl')]: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end'
                                    },
                                }}
                            >

                                {bullImage?.URL !== undefined && <CardContent>
                                    <CardMedia
                                        sx={{
                                            [theme.breakpoints.up('md')]: {
                                                height: '340px',
                                                width: '35vw'
                                            },
                                            [theme.breakpoints.down('md')]: {
                                                height: '30vh',
                                                width: '100%'
                                            },
                                            [theme.breakpoints.down('sm')]: {
                                                height: '210px'
                                            }
                                        }}
                                        component='img'
                                        width='100%'
                                        image={bullImage?.URL}
                                        alt={bullImage?.Caption}
                                    />
                                </CardContent>}
                            </Box>
                        </Card>
                    </a>
                </Box>

                {/* CARD 3 STARTS HERE */}

                <Box sx={{
                    marginTop: '1.4rem',
                    padding: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                    <a href="/Longhorns/List/steers" style={{
                        textDecoration: 'none',
                        borderColor: 'none'
                    }}>
                        <Card
                            variant='outlined'
                            sx={{
                                gap: 2,
                                [theme.breakpoints.up('md')]: {
                                    display: 'flex',
                                    width: '76vw'
                                },
                                [theme.breakpoints.down('md')]: {
                                    width: '76vw'
                                },
                            }}
                        >
                            <Box sx={{
                                [theme.breakpoints.up('md')]: {
                                    padding: '3rem'
                                },
                                [theme.breakpoints.down('md')]: {
                                    padding: '2rem'
                                },
                            }}>
                                <Typography
                                    variant='h4'
                                    component='div'
                                >
                                    Steers
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    component='div'
                                    marginTop='.8rem'
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            width: '24vw',
                                            fontSize: '1.6rem'
                                        },
                                        [theme.breakpoints.down('md')]: {
                                            width: '48vw',
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            width: 'auto'
                                        },
                                    }}
                                >
                                    {steerRole.Description && steerRole.Description.slice(0,200) + "..."}
                                </Typography>
                                <Box
                                    mt={'1rem'}
                                    ml={'-.4rem'}
                                    sx={{ opacity: '60%' }}
                                >
                                    <Button
                                        variant='text'
                                        endIcon={<CallMadeIcon sx={{
                                            color: colors.palette.text.primary
                                        }} />}
                                    >
                                        <Typography variant='cardButton'>
                                            See steers
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    [theme.breakpoints.between('md', 'xl')]: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'end'
                                    },
                                }}
                            >
                                {steerImage?.URL !== undefined &&
                                    <CardContent>
                                        <CardMedia
                                            sx={{
                                                [theme.breakpoints.up('md')]: {
                                                    height: '340px',
                                                    width: '35vw'
                                                },
                                                [theme.breakpoints.down('md')]: {
                                                    height: '30vh',
                                                    width: '100%'
                                                },
                                                [theme.breakpoints.down('sm')]: {
                                                    height: '210px'
                                                }
                                            }}
                                            component='img'
                                            width='100%'
                                            image={steerImage?.URL}
                                            alt={steerImage?.Caption}
                                        />
                                    </CardContent>}
                            </Box>
                        </Card>
                    </a>
                </Box>

            </Box>
        </Box>
    )
}