import React, { useEffect, useState } from 'react';
import { Box, Button, TableContainer, TableBody, Table, TableHead, TableRow, TableCell, Paper, Typography } from "@mui/material";
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

export const AdminLonghornTable: React.FC = () => {

  // fetch longhorn data from server and store it in the "longhornsList" state variable
  const [longhornsList, setLonghornsList] = useState<{ LonghornID: number, RanchPrefix: string, Name: string, IsExternal: boolean }[]>([]);

  useEffect(() => {
    async function getLonghorns() {
      let response = await fetch('https://hcrapi.azurewebsites.net/longhorns', { method: 'get' });
      let data = await response.json();
      setLonghornsList(await data);
    };

    if (longhornsList.length === 0) {
      getLonghorns();
    }
  }, [longhornsList.length]);

  const username = sessionStorage.getItem('username')?.slice(1, -1);
  const password = sessionStorage.getItem('password')?.slice(1, -1);

  // define the "deleteLonghorn" function
  function deleteLonghorn(e: any) {
    if (window.confirm("Are you sure you want to delete this longhorn? This action cannot be undone.")) {
      var longhornID = e.target.getAttribute("data-longhornid")
      try {
        // delete longhorn from server using the longhornID
        fetch(`https://hcrapi.azurewebsites.net/longhorns/${longhornID}/remove`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            // provide authentication details
            Username: username,
            Password: password
          })
        });

        // remove the longhorn from the longhornsList state variable
        setLonghornsList(longhornsList.filter(longhorn => longhorn.LonghornID !== longhornID));

        window.location.reload();

        console.log("Longhorn with ID " + longhornID + " marked as archived")
      } catch {
        console.log("An error has occurred")
      }
    }
  }

  return (
    <Box display={'flex'} justifyContent={'center'} marginBottom={20}>
      <TableContainer sx={{ maxWidth: '75vw' }} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>LonghornID</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {longhornsList &&
              longhornsList.map(({ LonghornID, RanchPrefix, Name, IsExternal }: { LonghornID: number, RanchPrefix: string, Name: string, IsExternal: boolean }) => (
                <TableRow key={LonghornID}>
                  <TableCell component="th" scope="row">
                    {LonghornID}
                  </TableCell>
                  <TableCell align="left">
                    <>
                      <Box display={'flex'} flexDirection={'row'} sx={{
                        [theme.breakpoints.down('sm')]: {
                          flexDirection: 'column'
                        }
                      }}>
                        <Typography sx={{ color: colors.palette.primary.light, fontSize: '1rem', paddingRight: 1 }}>{IsExternal ? 'E -' : ''}</Typography>
                        <Typography sx={{ color: colors.palette.text.secondary, fontSize: '1rem', paddingRight: 1 }}>{RanchPrefix}</Typography>
                        <Typography sx={{ color: colors.palette.text.secondary, fontSize: '1rem' }}>{Name}</Typography>
                      </Box>
                    </>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="text"
                      sx={{ color: colors.palette.primary.main, opacity: '70%' }}
                      href={`/Admin/Longhorns/${LonghornID}/TipMeasures`}
                    >
                      Tip-to-Tip Measurements
                    </Button>
                    <Button
                      variant="text"
                      sx={{ color: colors.palette.primary.light }}
                      href={`/Admin/Longhorns/${LonghornID}`}
                    >
                      Edit
                    </Button>
                    <Button
                      key={'btn' + LonghornID}
                      data-longhornid={LonghornID}
                      onClick={deleteLonghorn}
                      sx={{
                        color: colors.palette.text.secondary,
                        opacity: '60%'
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}