import AdminImageUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { Box, Button, Typography, Divider } from "@mui/material";
import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { AdminLonghornTable } from "../../components/Admin/AdminLonghornTable";

export const AdminLonghorn: React.FC = () => {

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      let response = await fetch('https://hcrapi.azurewebsites.net/longhorns/0/images', { method: 'get' })
      let data = await response.json();
      setImageList(await data);
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length]);

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  return (
    <>
      <Navbar />
      <Box sx={{
        backgroundColor: colors.palette.background.default
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              Edit Longhorns
            </Typography>
          </Box>
        </Box>
      </Box>
      <AdminImageUI id={0} type="longhorn" title="Upload photos for general longhorn slideshow" imageList={imageList}></AdminImageUI>
      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}
        gap={5}
      >
        <Button variant='text'
          endIcon={<CallMadeIcon />}
          href="/Admin/Longhorns/Roles"
          sx={{
            color: colors.palette.primary.light, fontSize: '1.2rem',
            [theme.breakpoints.down('md')]: {
              paddingTop: '4rem',
              width: '60vw'
            }
          }}>
          Edit Representative Photos for Roles (Steers, Bulls, etc)
        </Button>
      </Box>
      <Box display={'flex'} justifyContent={'center'} paddingTop={4} paddingBottom={8}>
        <Divider sx={{ width: '40%', backgroundColor: colors.palette.primary.light }} />
      </Box>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '6vh',
            paddingBottom: ''
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '12vh',
            paddingBottom: '3vh'
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
              justifyContent: 'start'
            }
          }}
        >
          <Typography
            color={colors.palette.text.secondary}
            sx={{
              opacity: '100%',
              [theme.breakpoints.down('md')]: {
                justifyContent: 'start',
                width: '100%'
              }
            }}
            variant='h4'
            fontWeight={600}
          >
            All Longhorns
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          [theme.breakpoints.up('sm')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '2vh',
            paddingBottom: '8vh'
          },
          [theme.breakpoints.down('sm')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '2vh',
            paddingBottom: '6vh'
          }
        }}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
      >
        <Typography
          variant='body2'
          width={'100%'}
          color={colors.palette.text.secondary}
          sx={{
            opacity: '40%',
            paddingTop: '4px',
            textAlign: 'center',
            fontWeight: 400,
            [theme.breakpoints.down('md')]: {
              textAlign: 'start'
            }
          }}
        >
          Items beginning with "E" are external longhorns and are only in system for pedigree purposes.
        </Typography>
        <Box
          display={'flex'}
          justifyContent={'center'}
          width='100%'
          paddingTop='1rem'
          sx={{
            [theme.breakpoints.down('md')]: {
              justifyContent: 'start'
            }
          }}
        >
          <Button variant='text'
            endIcon={<CallMadeIcon />}
            href="/admin/longhorns/0"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: colors.palette.primary.light,
              fontSize: '1.2rem'
            }}
          >
            Create New
          </Button>
        </Box>
      </Box>
      <AdminLonghornTable />
    </>
  )
}