import AdminImageUploadUI from "../../components/Admin/AdminImageUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React, { useEffect, useState } from 'react';
import Login from "../../components/Admin/Login";
import GetRequest from "../../Get";
import useToken from "../../components/Admin/SendLogin";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../styles/colors";
import { theme } from "../../styles/theme";
import { ChevronLeft } from "@mui/icons-material";
import CallMadeIcon from "@mui/icons-material/CallMade";

export const AdminExotic: React.FC = () => {

  var exotics = GetRequest("exotics/breeds");
  var exoticsList = exotics[0]

  const username = sessionStorage.getItem('username')?.slice(1, -1);
  const password = sessionStorage.getItem('password')?.slice(1, -1);

  const [imageList, setImageList] = useState<{ URL: string, Caption: string }[]>([]);

  useEffect(() => {
    async function getImages() {
      let response = await fetch('https://hcrapi.azurewebsites.net/exotics/0/images', { method: 'get' })
      let data = await response.json();
      setImageList(await data);
    };

    if (imageList.length === 0) {
      getImages();
    }
  }, [imageList.length]);

  const { token, setToken } = useToken();

  if (!token) {
    return <Login setToken={setToken} token={""} />
  }

  function deleteExotic(e: any) {
    if (window.confirm("Are you sure you want to delete this exotic? This action cannot be undone.")) {
      var BreedID = e.target.getAttribute("data-breedid")
      try {
        fetch(`https://hcrapi.azurewebsites.net/exotics/breeds/${BreedID}/remove`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              Username: username,
              Password: password
            })
        })

        var div = document.getElementById(BreedID)
        if (div) {
          div.style.display = "none";
        }
      }
      catch {
        console.log("An error has occurred")
      }

    }

  }

  return (
    <>
      <Navbar />
      <Box sx={{
        backgroundColor: colors.palette.background.default
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              Edit Exotics
            </Typography>
          </Box>
        </Box>
      </Box>
      <AdminImageUploadUI id={0} type="exoticbreeder" title="Upload images for exotics slideshow" imageList={imageList}></AdminImageUploadUI>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '4vh',
            textAlign: 'center'
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: '12vw',
            paddingRight: '12vw',
            paddingTop: '4vh',
            paddingBottom: '3vh',
            textAlign: 'start'
          }
        }}
      >
        <Button variant='text'
          endIcon={<CallMadeIcon />}
          href="/Admin/Exotics/0" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
          Create New
        </Button>
      </Box>
      <Box
        sx={{
          [theme.breakpoints.up('md')]: {
            paddingLeft: 0
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: 2
          }
        }}
        marginBottom={20}
      >
        {exoticsList && exoticsList.map(({ BreedID, Name }: any) => (
          <Box id={BreedID} key={BreedID}
            sx={{
              [theme.breakpoints.up('md')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                textAlign: 'center',
                marginBottom: 3
              },
              [theme.breakpoints.down('md')]: {
                paddingLeft: '12vw',
                paddingRight: '12vw',
                textAlign: 'start',
                marginBottom: 3
              },
              display: 'flex',
              flexDirection: 'column',
              gap: .2
            }}
          >
            <Box>
              <Typography variant='body1' sx={{ color: colors.palette.text.secondary }}>{Name}</Typography>
            </Box>
            <Box>
              <Button variant="text" key={"btn" + BreedID} data-breedid={BreedID} onClick={deleteExotic} sx={{ color: colors.palette.text.secondary, opacity: '80%' }}>Delete</Button>
              <Button variant="text" href={`/Admin/Exotics/${Number(BreedID)}`} key={BreedID} sx={{ color: colors.palette.primary.light }}> Edit </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  )
}