import { ChevronLeft } from '@mui/icons-material';
import { Box, Button, Typography, TextField, Stack } from '@mui/material';
import { useEffect, useState } from 'react'
import { colors } from '../../styles/colors';
import { theme } from '../../styles/theme';

type props = {
  AffiliationID: number
};

export default function AdminEditAffiliationForm(props: props) {

  const [affiliation, setAffiliation] = useState<{ AffiliationID: number, URL: string, Name: string }>({ AffiliationID: 0, URL: "", Name: "" })

  useEffect(() => {
    async function getAff() {
      let response = await fetch(`https://hcrapi.azurewebsites.net/Affiliations/` + props.AffiliationID, { method: 'get' })
      let data = await response.json();
      setAffiliation(data[0]);
    };

    if (props.AffiliationID) {
        getAff();
    }

  }, [props.AffiliationID]);

  const handleChange = (e: any) => {
    if (e) {
      setAffiliation({...affiliation, [e.target.name]: e.target.value })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    console.log(JSON.stringify({
      ...affiliation
    }))

    const username = sessionStorage.getItem('username')?.slice(1, -1);
    const password = sessionStorage.getItem('password')?.slice(1, -1);

    try {
      if (props.AffiliationID !== 0) {
        fetch(`https://hcrapi.azurewebsites.net/Affiliations/${props.AffiliationID}/edit`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...affiliation,
              Username: username,
              Password: password
            })
        })
      }

      else {
        fetch(`https://hcrapi.azurewebsites.net/Affiliations/add`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body:
            JSON.stringify({
              ...affiliation,
              Username: username,
              Password: password
            })
        })
      }
      let message = document.getElementById("message")
      if (message) {
        message.innerHTML = "Operation completed successfully";
        setTimeout(function () {
          window.location.href = "/Admin/Affiliations"
        }, 1500)
      }
    }
    catch {
      console.log("Operation failed")
    }
  }

  return (
    <>
      <Box sx={{
        backgroundColor: colors.palette.background.default,
        color: colors.palette.text.secondary
      }}>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '4vh'
            },
            [theme.breakpoints.down('md')]: {
              paddingLeft: '12vw',
              paddingRight: '12vw',
              paddingTop: '4vh',
              paddingBottom: '3vh'
            }
          }}>
          <Box sx={{ paddingBottom: '8vh' }}>
            <Button startIcon={<ChevronLeft />} variant='text' href='/Admin/Affiliations' sx={{ color: colors.palette.primary.light }}>
              Go Back
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.down('sm')]: {
                justifyContent: 'start'
              }
            }}
          >
            <Typography
              color={colors.palette.text.secondary}
              sx={{
                opacity: '100%',
                [theme.breakpoints.down('md')]: {
                  justifyContent: 'start',
                  width: '100%'
                }
              }}
              variant='h3'
              fontWeight={600}
            >
              {affiliation.Name ? ('Edit ' + affiliation.Name + "'s Information") : ('Create Affiliation')}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              [theme.breakpoints.up('sm')]: {
                paddingTop: '2vh',
                paddingBottom: '8vh'
              },
              [theme.breakpoints.down('sm')]: {
                paddingTop: '2vh',
                paddingBottom: '6vh'
              }
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            {affiliation.AffiliationID === 0 &&
              <Typography
                variant='body2'
                width={'100%'}
                color={colors.palette.text.secondary}
                sx={{
                  opacity: '40%',
                  paddingTop: '4px',
                  textAlign: 'center',
                  fontWeight: 400,
                  [theme.breakpoints.down('md')]: {
                    textAlign: 'start'
                  }
                }}
              >
                Images can be uploaded after creating the Affiliation and editing them from the previous screen
              </Typography>}
          </Box>
          <Box display={'flex'} justifyContent={'center'} mb={20}>
            <form className={'App'} onSubmit={handleSubmit} style={{ width: '20rem' }}>
              <Stack spacing={3}>
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  required
                  label="Name"
                  name="Name"
                  id="Name"
                  type="text"
                  fullWidth
                  value={affiliation.Name}
                  placeholder="Name"
                  onChange={handleChange}
                  key="Name"
                />
                <TextField
                  sx={{
                    '& .MuiFormLabel-root': {
                      color: colors.palette.text.secondary + '!important'
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor: colors.palette.primary.light
                      },
                      '&:hover fieldset': {
                        borderColor: colors.palette.secondary.light
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: colors.palette.primary.main
                      },
                    },
                  }}
                  label="URL"
                  name="URL"
                  type="text"
                  fullWidth
                  value={affiliation.URL}
                  placeholder="Link to site"
                  onChange={handleChange}
                  key="URL"
                />
                <Box display={'flex'} justifyContent={'end'} pt={2}>
                  <Button sx={{ color: colors.palette.primary.light }} type="submit">{props.AffiliationID === 0 ? 'Create' : 'Save Changes'}</Button>
                  <Box>
                    <label id="message"></label>
                  </Box>
                </Box>
              </Stack>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  )
}