// import AdminImageUploadUI from "../../components/Admin/AdminImageUploadUI"
import Navbar from "../../components/Global/Navbar/Navbar"
import React from 'react';
import { Box, Typography, Button } from "@mui/material";
import Login from "../../components/Admin/Login";
import useToken from "../../components/Admin/SendLogin";
import { theme } from "../../styles/theme";
import { colors } from "../../styles/colors";
import CallMadeIcon from "@mui/icons-material/CallMade";

export function Admin() {

    const { token, setToken } = useToken();

    if (!token) {
        return <Login setToken={setToken} token={""} />
    }

    return (
        <>
            <Navbar />
            <Box
                sx={{
                    [theme.breakpoints.up('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '6vw',
                        paddingBottom: '13vw'
                    },
                    [theme.breakpoints.down('sm')]: {
                        paddingLeft: '12vw',
                        paddingRight: '12vw',
                        paddingTop: '6vw',
                        paddingBottom: '6vw'
                    }
                }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    alignItems={'center'}
                >
                    <Typography
                        variant='h2'
                        mb={10}
                        textAlign={'center'}
                        sx={{ color: colors.palette.text.secondary }}
                    >
                        Edit Portal
                    </Typography>
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        gap={5}
                    >
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Longhorns" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Longhorns
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Exotics" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Exotics
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Denizens" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Upload or Remove Denizen Photos
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Ranch" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Upload or Remove Ranch Photos
                        </Button>
                        <Button variant='text'
                            endIcon={<CallMadeIcon />}
                            href="/Admin/Affiliations" sx={{ color: colors.palette.primary.light, fontSize: '1.2rem' }}>
                            Edit Affiliations
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    )
}