import { useState, useEffect } from "react";
import axios from "axios";

export default function GetRequest(request: string) {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
  
    useEffect(() => {
      const getData = async () => {
        try {
          const response = await axios.get(
            `https://hcrapi.azurewebsites.net/` + request
          );
          
          setData(response.data);
          setError(null);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
            
          setData(null);
        } finally {
          setLoading(false);
        }
      };

      getData();
      
    }, [request]);

    return [data, loading, error];
}